export default {
  NOT_AVAILABLE: {
    display: 'N/A',
  },
  CRITICAL: {
    display: 'Critical',
    severity: 2,
  },
  CAUTION: {
    display: 'Caution',
    severity: 1,
  },
  GOOD: {
    display: 'Good',
    severity: 0,
  },
  UNSTABLE: {
    display: 'Unstable',
  },
  LOW: {
    display: 'Low',
  },
  NOTICE: {
    display: 'Notice',
  },
};
