import CustomFilter from '../../components/CurrentFleetStatus/components/CustomFilter';
import {
  formatAddressValueAgGrid,
  formatHoursAgGrid,
  formatLightsValueAgGrid,
  formatPercentageAgGrid,
  formatSpeedValueAgGrid,
  formatTempAgGrid,
  formatTISValueAgGrid,
  formatValueAgGrid,
  formatVoltageAgGrid,
  getFormattedTimestampAgGrid,
  getUtcTimestampFormattedAsUserTimezoneAgGrid,
} from '../../utils/agGridUtils';
import { formatWeightStatus } from '../../utils/currentFleetStatusUtils';

const DEFAULT_COLUMN_SIZE = 140;
const VIN_COLUMN_SIZE = 199;
const SERIAL_NUMBER_COLUMN_SIZE = 166;

export default {
  VIN_NUMBER: {
    displayName: 'VIN',
    apiResponseParsePath: 'vin',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'VIN',
    name: 'vin',
    isColumnHidden: false,
    icon: 'vinIcon',
    key: 'VIN_NUMBER',
    fieldName: 'vin',
    width: VIN_COLUMN_SIZE,
  },
  UNIT_ID: {
    displayName: 'Unit ID',
    apiResponseParsePath: 'unitId',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Unit ID',
    name: 'unitId',
    isColumnHidden: false,
    icon: 'unitIdIcon',
    key: 'UNIT_ID',
    fieldName: 'unit_id',
    width: DEFAULT_COLUMN_SIZE,
  },
  CUSTOMER_NAME: {
    displayName: 'Customer Name',
    apiResponseParsePath: 'customerName',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Customer Name',
    name: 'customerName',
    isColumnHidden: false,
    icon: 'customerNameIcon',
    key: 'CUSTOMER_NAME',
    fieldName: 'customer_name',
    width: DEFAULT_COLUMN_SIZE,
  },
  SERIAL_NUMBER: {
    displayName: 'Serial Number',
    apiResponseParsePath: 'serialNumber',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Serial Number',
    name: 'serialNumber',
    isColumnHidden: false,
    icon: 'serialNumberIcon',
    key: 'SERIAL_NUMBER',
    fieldName: 'serial_num',
    width: SERIAL_NUMBER_COLUMN_SIZE,
  },
  SOLAR_PANEL_STATUS: {
    displayName: 'Solar Panel Status',
    apiResponseParsePath: 'hasSolarPanels',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customSolarPanelStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Solar Panel Status',
    name: 'hasSolarPanels',
    isColumnHidden: false,
    icon: 'solarPanelStatusIcon',
    key: 'SOLAR_PANEL_STATUS',
    columnFilteringOptions: ['Yes', 'No'],
    columnIdentifier: 'solarPanelStatusDropdown',
    fieldName: 'has_solar_panels',
    width: DEFAULT_COLUMN_SIZE,
  },
  PRODUCT_TYPE: {
    displayName: 'Product Type',
    apiResponseParsePath: 'productType',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customProductTypeFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Product Type',
    name: 'productType',
    isColumnHidden: false,
    icon: 'productTypeIcon',
    key: 'PRODUCT_TYPE',
    columnFilteringOptions: ['Pro', 'Base', 'GO: Solar', 'GO: Track', 'GO: Cargo View', 'GO: Cargo Monitor', 'TRU'],
    columnIdentifier: 'productTypeDropdown',
    fieldName: 'go_product_type',
    width: DEFAULT_COLUMN_SIZE,
  },
  FIRMWARE_VERSION: {
    displayName: 'Firmware Version',
    apiResponseParsePath: 'mainAppVersion',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Firmware Version',
    name: 'mainAppVersion',
    isColumnHidden: false,
    icon: 'firmwareVersionIcon',
    key: 'FIRMWARE_VERSION',
    fieldName: 'firmware_version',
    width: DEFAULT_COLUMN_SIZE,
  },
  LAST_REPORT_DATE: {
    displayName: 'Last Reported Date',
    apiResponseParsePath: 'lastReport',
    partsPage: {
      valueFormatter: getUtcTimestampFormattedAsUserTimezoneAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true,
        colId: 'lastReport',
      },
    },
    title: 'Last Reported Date',
    name: 'lastReport',
    isColumnHidden: false,
    icon: 'lastReportDateIcon',
    key: 'LAST_REPORT_DATE',
    fieldName: 'last_reported_calculated',
    width: DEFAULT_COLUMN_SIZE,
  },
  INSTALLATION_DATE: {
    displayName: 'Installation Date',
    apiResponseParsePath: 'installationDate',
    partsPage: {
      valueFormatter: getFormattedTimestampAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Installation Date',
    name: 'installationDate',
    isColumnHidden: false,
    icon: 'installationDateIcon',
    key: 'INSTALLATION_DATE',
    fieldName: 'installation_date',
    width: DEFAULT_COLUMN_SIZE,
  },
  GROUP: {
    displayName: 'Group',
    apiResponseParsePath: 'group',
    partsPage: {
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Group',
    name: 'group',
    isColumnHidden: false,
    icon: 'groupIcon',
    key: 'GROUP',
    fieldName: 'groups',
    width: DEFAULT_COLUMN_SIZE,
  },
  ABS_ODOMETER: {
    displayName: 'ABS Odometer',
    apiResponseParsePath: 'odometer',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'ABS Odometer',
    name: 'odometer',
    isColumnHidden: false,
    icon: 'odometerIcon',
    key: 'ABS_ODOMETER',
    fieldName: 'abs_odometer_miles',
    width: DEFAULT_COLUMN_SIZE,
  },
  GPS_ODOMETER: {
    displayName: 'GPS Odometer',
    apiResponseParsePath: 'gpsOdometer',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'GPS Odometer',
    name: 'gpsOdometer',
    isColumnHidden: false,
    icon: 'gpsOdometerIcon',
    key: 'GPS_ODOMETER',
    fieldName: 'gps_odometer_miles',
    width: DEFAULT_COLUMN_SIZE,
  },
  TETHERED_STATUS: {
    displayName: 'Tethered Status',
    apiResponseParsePath: 'tether',
    partsPage: {
      valueFormatter: formatTISValueAgGrid,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customTetherStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Tethered Status',
    name: 'tether',
    isColumnHidden: false,
    icon: 'tetherIcon',
    key: 'TETHERED_STATUS',
    columnName: 'tether',
    columnIdentifier: 'tetherDropdown',
    columnFilteringOptions: ['tethered', 'untethered'],
    fieldName: 'tethered_status_calculated',
    width: DEFAULT_COLUMN_SIZE,
  },
  TIME_UNTETHERED: {
    displayName: 'Time Untethered',
    apiResponseParsePath: 'timeUntetheredFormattedString',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true,
        colId: 'timeUntetheredFormattedString',
      },
    },
    title: 'Time Untethered',
    name: 'timeUntetheredFormattedString',
    isColumnHidden: false,
    icon: 'untetheredSinceIcon',
    key: 'TIME_UNTETHERED',
    fieldName: 'time_untethered_calculated',
    width: DEFAULT_COLUMN_SIZE,
  },
  GEOFENCE: {
    displayName: 'Geofence',
    apiResponseParsePath: 'occupiedCustomerSpecificGeofences',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Geofence',
    name: 'occupiedCustomerSpecificGeofences',
    isColumnHidden: false,
    icon: 'geofenceIcon',
    key: 'GEOFENCE',
    fieldName: 'geofence',
    width: DEFAULT_COLUMN_SIZE,
  },
  LOAD_STATUS: {
    displayName: 'Load Status',
    apiResponseParsePath: 'enhancedWeight',
    partsPage: {
      valueFormatter: formatTISValueAgGrid,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customLoadStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Load Status',
    name: 'enhancedWeight',
    isColumnHidden: false,
    icon: 'loadStatusIcon',
    key: 'LOAD_STATUS',
    columnName: 'enhancedWeight',
    columnIdentifier: 'loadDropdown',
    columnFilteringOptions: ['loaded', 'unloaded'],
    fieldName: 'is_loaded_calculated',
    width: DEFAULT_COLUMN_SIZE,
  },
  CARGO_BOGIE_WEIGHT: {
    displayName: 'Cargo Bogie Weight',
    apiResponseParsePath: 'netWeightLbs',
    partsPage: {
      valueFormatter: formatWeightStatus,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Cargo Bogie Weight',
    name: 'netWeightLbs',
    isColumnHidden: false,
    icon: 'cargoBogieWeightIcon',
    key: 'CARGO_BOGIE_WEIGHT',
    fieldName: 'weight_lbs',
    width: DEFAULT_COLUMN_SIZE,
  },
  ADDRESS: {
    displayName: 'Address',
    apiResponseParsePath: 'reverseGeo',
    partsPage: {
      valueFormatter: formatAddressValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Address',
    name: 'reverseGeo',
    isColumnHidden: false,
    icon: 'addressIcon',
    key: 'ADDRESS',
    fieldName: 'reverse_geocoded_address',
    width: DEFAULT_COLUMN_SIZE,
  },
  SPEED: {
    displayName: 'Speed (mph)',
    apiResponseParsePath: 'speed',
    partsPage: {
      valueFormatter: formatSpeedValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Speed (mph)',
    name: 'speed',
    isColumnHidden: false,
    icon: 'speedIcon',
    key: 'SPEED',
    fieldName: 'speed_mph',
    width: DEFAULT_COLUMN_SIZE,
  },
  DOOR_STATUS: {
    displayName: 'Door Status',
    apiResponseParsePath: 'door',
    partsPage: {
      valueFormatter: formatTISValueAgGrid,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customDoorStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Door Status',
    name: 'door',
    isColumnHidden: false,
    icon: 'doorsIcon',
    key: 'DOOR_STATUS',
    columnName: 'door',
    columnIdentifier: 'doorDropdown',
    columnFilteringOptions: ['open', 'closed'],
    fieldName: 'door_status_calculated',
    width: DEFAULT_COLUMN_SIZE,
  },
  ABS_FAULT: {
    displayName: 'ABS Faults',
    apiResponseParsePath: 'absFault',
    partsPage: {
      valueFormatter: formatLightsValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'ABS Faults',
    name: 'absFault',
    isColumnHidden: false,
    icon: 'absIcon',
    key: 'ABS_FAULT',
    fieldName: 'abs_fault_codes',
    width: DEFAULT_COLUMN_SIZE,
  },
  TIRE_INFLATION_STATUS: {
    displayName: 'Tire Inflation Status',
    apiResponseParsePath: 'tis',
    partsPage: {
      valueFormatter: formatTISValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customTisStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Tire Inflation Status',
    name: 'tis',
    isColumnHidden: false,
    icon: 'tisIcon',
    key: 'TIRE_INFLATION_STATUS',
    columnName: 'tis',
    columnIdentifier: 'tisDropdown',
    columnFilteringOptions: ['stable', 'unstable', 'not available'],
    fieldName: 'tire_inflation_status',
    width: DEFAULT_COLUMN_SIZE,
  },
  LIGHTS_FAULTS: {
    displayName: 'Lights Faults',
    apiResponseParsePath: 'lightFault',
    partsPage: {
      valueFormatter: formatLightsValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Lights Faults',
    name: 'lightFault',
    isColumnHidden: false,
    icon: 'lightsIcon',
    key: 'LIGHTS_FAULTS',
    columnName: 'lightFault',
    columnIdentifier: 'lightsDropdown',
    columnFilteringOptions: ['intermittent', 'low current', 'multiple', 'unknown'],
    fieldName: 'light_fault_codes',
    width: DEFAULT_COLUMN_SIZE,
  },
  TRAILER_BATTERY_VOLTAGE: {
    displayName: 'Trailer Battery Voltage',
    apiResponseParsePath: 'sensorBoxBattery',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Trailer Battery Voltage',
    name: 'sensorBoxBattery',
    isColumnHidden: false,
    icon: 'boxBattIcon',
    key: 'TRAILER_BATTERY_VOLTAGE',
    fieldName: 'sensor_box_battery_voltage',
    width: DEFAULT_COLUMN_SIZE,
  },
  TRAILER_BATTERY_HEALTH_STATUS: {
    displayName: 'Trailer Battery Health Status',
    apiResponseParsePath: 'sensorBoxBatteryHealthStatus',
    partsPage: {
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customBatteryHealthStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Trailer Battery Health Status',
    name: 'sensorBoxBatteryHealthStatus',
    isColumnHidden: false,
    icon: 'boxBattIcon',
    key: 'TRAILER_BATTERY_HEALTH_STATUS',
    columnName: 'sensorBoxBatteryHealthStatus',
    columnIdentifier: 'batteryHealthDropdown',
    columnFilteringOptions: ['GOOD', 'NOTICE', 'NA'],
    fieldName: 'trailer_battery_health_status',
    width: DEFAULT_COLUMN_SIZE,
  },
  GATEWAY_BATTERY: {
    displayName: 'Gateway Battery',
    apiResponseParsePath: 'gatewayBatteryHealthStatus',
    partsPage: {
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customGatewayBatteryStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Gateway Battery',
    name: 'gatewayBatteryHealthStatus',
    isColumnHidden: false,
    icon: 'boxBattIcon',
    key: 'GATEWAY_BATTERY',
    columnName: 'gatewayBatteryHealthStatus',
    columnIdentifier: 'gatewayBatteryHealthDropdown',
    columnFilteringOptions: ['GOOD', 'NOTICE', 'NA'],
    fieldName: 'gateway_battery_health_status',
    width: DEFAULT_COLUMN_SIZE,
  },
  TRAILER_HEALTH: {
    displayName: 'Trailer Health Status',
    apiResponseParsePath: 'trailerHealthStatus',
    partsPage: {
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customTrailerHealthStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Trailer Health Status',
    name: 'trailerHealthStatus',
    isColumnHidden: false,
    icon: 'boxBattIcon',
    key: 'TRAILER_HEALTH',
    columnName: 'trailerHealthStatus',
    columnIdentifier: 'trailerHealthDropdown',
    columnFilteringOptions: ['GOOD', 'CAUTION', 'CRITICAL', 'NA'],
    fieldName: 'trailer_health_status',
    width: DEFAULT_COLUMN_SIZE,
  },
  CONNECTIVITY_STATUS: {
    displayName: 'Connectivity Status',
    apiResponseParsePath: 'connectivityStatus',
    partsPage: {
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customConnectivityStatusFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Connectivity Status',
    name: 'connectivityStatus',
    isColumnHidden: false,
    icon: 'boxBattIcon',
    key: 'CONNECTIVITY_STATUS',
    columnName: 'connectivityStatus',
    columnIdentifier: 'connectivityStatusDropdown',
    columnFilteringOptions: ['GOOD', 'CAUTION', 'CRITICAL', 'NA'],
    fieldName: 'sensor_connectivity_status',
    width: DEFAULT_COLUMN_SIZE,
  },
  LATITUDE: {
    displayName: 'Latitude',
    apiResponseParsePath: 'lat',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Latitude',
    name: 'lat',
    isColumnHidden: false,
    icon: 'gpsIcon',
    key: 'LATITUDE',
    fieldName: 'lat',
    width: DEFAULT_COLUMN_SIZE,
  },
  LONGITUDE: {
    displayName: 'Longitude',
    apiResponseParsePath: 'lng',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Longitude',
    name: 'lng',
    isColumnHidden: false,
    icon: 'gpsIcon',
    key: 'LONGITUDE',
    fieldName: 'lon',
    width: DEFAULT_COLUMN_SIZE,
  },
  CARGO_CAMERA_FULLNESS: {
    displayName: 'Cargo Camera Fullness',
    apiResponseParsePath: 'fullness',
    partsPage: {
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Cargo Camera Fullness',
    name: 'fullness',
    isColumnHidden: false,
    icon: 'cameraIcon',
    key: 'CARGO_CAMERA_FULLNESS',
    fieldName: 'camera_fullness',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_UNIT_ON_OFF: {
    displayName: 'TK Unit on/off',
    apiResponseParsePath: 'powerOn',
    partsPage: {
      valueFormatter: formatValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customTKUnitOnOffFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Thermo King',
    name: 'powerOn',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_UNIT_ON_OFF',
    columnName: 'powerOn',
    columnIdentifier: 'tkUnitOnOffDropdown',
    columnFilteringOptions: ['on', 'off'],
    fieldName: 'tk_unit_on',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_OPERATING_MODE: {
    displayName: 'TK Operating Mode',
    apiResponseParsePath: 'unitModeDetail',
    partsPage: {
      valueFormatter: formatValueAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'customTKOperatingModeFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    title: 'Thermo King',
    name: 'unitModeDetail',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_OPERATING_MODE',
    columnName: 'unitModeDetail',
    columnIdentifier: 'tkOperatingModeDropdown',
    columnFilteringOptions: ['cycle Sentry', 'continuous'],
    fieldName: 'tk_operating_mode',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_FUEL_LEVEL: {
    displayName: 'TK Fuel Level',
    apiResponseParsePath: 'fuelLevel',
    partsPage: {
      valueFormatter: formatPercentageAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'fuelLevel',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_FUEL_LEVEL',
    fieldName: 'tk_fuel_level',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_AMBIENT_TEMP: {
    displayName: 'TK Ambient Temp',
    apiResponseParsePath: 'ambientTemperature',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'ambientTemperature',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_AMBIENT_TEMP',
    fieldName: 'tk_ambient_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_HUMIDITY: {
    displayName: 'TK Humidity',
    apiResponseParsePath: 'humidity',
    partsPage: {
      valueFormatter: formatPercentageAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'humidity',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_HUMIDITY',
    fieldName: 'tk_humidity',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP1: {
    displayName: 'Interior Sensor Temp 1',
    apiResponseParsePath: 'indSensor1',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor1',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP1',
    fieldName: 'interior_sensor_1_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP2: {
    displayName: 'Interior Sensor Temp 2',
    apiResponseParsePath: 'indSensor2',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor2',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP2',
    fieldName: 'interior_sensor_2_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP3: {
    displayName: 'Interior Sensor Temp 3',
    apiResponseParsePath: 'indSensor3',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor3',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP3',
    fieldName: 'interior_sensor_3_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP4: {
    displayName: 'Interior Sensor Temp 4',
    apiResponseParsePath: 'indSensor4',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor4',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP4',
    fieldName: 'interior_sensor_4_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP5: {
    displayName: 'Interior Sensor Temp 5',
    apiResponseParsePath: 'indSensor5',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor5',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP5',
    fieldName: 'interior_sensor_5_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SENSOR_TEMP6: {
    displayName: 'Interior Sensor Temp 6',
    apiResponseParsePath: 'indSensor6',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'indSensor6',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SENSOR_TEMP6',
    fieldName: 'interior_sensor_6_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SET_POINT1: {
    displayName: 'Set Point 1',
    apiResponseParsePath: 'setPoint1',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'setPoint1',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SET_POINT1',
    fieldName: 'set_point_1_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SET_POINT2: {
    displayName: 'Set Point 2',
    apiResponseParsePath: 'setPoint2',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'setPoint2',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SET_POINT2',
    fieldName: 'set_point_2_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_SET_POINT3: {
    displayName: 'Set Point 3',
    apiResponseParsePath: 'setPoint3',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'setPoint3',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_SET_POINT3',
    fieldName: 'set_point_3_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_DISCHARGE_AIR1: {
    displayName: 'Discharge Air 1',
    apiResponseParsePath: 'dischargeAir1',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'dischargeAir1',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_DISCHARGE_AIR1',
    fieldName: 'discharge_air_1_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_DISCHARGE_AIR2: {
    displayName: 'Discharge Air 2',
    apiResponseParsePath: 'dischargeAir2',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'dischargeAir2',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_DISCHARGE_AIR2',
    fieldName: 'discharge_air_2_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_DISCHARGE_AIR3: {
    displayName: 'Discharge Air 3',
    apiResponseParsePath: 'dischargeAir3',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'dischargeAir3',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_DISCHARGE_AIR3',
    fieldName: 'discharge_air_3_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_RETURN_AIR1: {
    displayName: 'Return Air 1',
    apiResponseParsePath: 'returnAir1',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'returnAir1',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_RETURN_AIR1',
    fieldName: 'return_air_1_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_RETURN_AIR2: {
    displayName: 'Return Air 2',
    apiResponseParsePath: 'returnAir2',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'returnAir2',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_RETURN_AIR2',
    fieldName: 'return_air_2_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_RETURN_AIR3: {
    displayName: 'Return Air 3',
    apiResponseParsePath: 'returnAir3',
    partsPage: {
      valueFormatter: formatTempAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'returnAir3',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_RETURN_AIR3',
    fieldName: 'return_air_3_temperature_f',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_ENGINE_HOURS: {
    displayName: 'Engine Hours',
    apiResponseParsePath: 'engineHours',
    partsPage: {
      valueFormatter: formatHoursAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'engineHours',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_ENGINE_HOURS',
    fieldName: 'engine_hours',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_ELECTRICAL_HOURS: {
    displayName: 'Electrical Hours',
    apiResponseParsePath: 'electricalHours',
    partsPage: {
      valueFormatter: formatHoursAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'electricalHours',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_ELECTRICAL_HOURS',
    fieldName: 'electrical_hours',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_TOTAL_HOURS: {
    displayName: 'Total Hours',
    apiResponseParsePath: 'totalHours',
    partsPage: {
      valueFormatter: formatHoursAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'totalHours',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_TOTAL_HOURS',
    fieldName: 'total_hours',
    width: DEFAULT_COLUMN_SIZE,
  },
  TK_UNIT_BATTERY_VOLTAGE: {
    displayName: 'Unit Battery Voltage',
    apiResponseParsePath: 'voltage',
    partsPage: {
      valueFormatter: formatVoltageAgGrid,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
    title: 'Thermo King',
    name: 'voltage',
    isColumnHidden: false,
    icon: 'reeferUnitIcon',
    key: 'TK_UNIT_BATTERY_VOLTAGE',
    fieldName: 'unit_battery_voltage',
    width: DEFAULT_COLUMN_SIZE,
  },
};
