import absIcon from '../../assets/public/brake.png';
import tisIcon from '../../assets/public/tire.png';
import batteryIcon from '../../assets/public/battery.png';
import lightIcon from '../../assets/public/lightsIcon.png'

export const FILTER_ICON_TITLE = {
  FLEET_HEALTH: null,
  ABS: absIcon,
  TIS: tisIcon,
  BATTERY: batteryIcon,
  LIGHT: lightIcon
};

export const TETHER_STATUS = {
  TETHERED: 'Tethered',
  UNTETHERED: 'Untethered',
};

export const LOAD_STATUS = {
  LOADED: 'Loaded',
  UNLOADED: 'Unloaded',
};

export const DEVICE_TYPE = {
  PRO: {
    id: 1,
    name: 'Pro',
  },
  BASE: {
    id: 2,
    name: 'Base',
  },
  GO: {
    id: 3,
    name: 'Go',
  },
};

export const SENSOR_CONNECTIVITY = {
  GOOD: 'Good',
  CAUTION: 'Caution',
  CRITICAL: 'Critical',
};

export const ITEM_TYPE = {
  DRY: 'Dry',
  REEFER: 'Reefer',
  FLAT: 'Flat',
};

export const COUNTED_FILTERS = {
  ABS_NO_FAULTS: 'AbsNoFaults',
  ABS_FAULTS: 'AbsFaults',
  ABS_ALERTS: 'AbsAlerts',
  TIS_NO_FAULTS: 'TisNoFaults',
  TIS_FAULTS: 'TisFaults',
  TIS_CAUTION_FAULTS: 'TisAlerts',
  BATTERY_NO_FAULTS: 'BatteryNoFaults',
  BATTERY_FAULTS: 'BatteryFaults',
  BATTERY_CAUTION_FAULTS: 'BatteryAlerts',
  LIGHT_NO_FAULTS: 'LightNoFaults',
  LIGHT_FAULTS: 'LightFaults',
  LIGHT_CAUTION_FAULTS: 'LightAlerts',
};

export const ROW_FILTERS = {
  NO_FAULTS: 'noFaultsSelected',
  ALERT: 'alertsSelected',
  FAULT: 'faultsSelected',
};

export const GROUPS_FILTER = 'Groups';

export const PRODUCT_TYPE = {
  PRO: {
    name: 'PRO',
    value: 'pro',
  },
  BASE: {
    name: 'Base',
    value: 'base',
  },
  GO_SOLAR: {
    name: 'GO Solar',
    value: 'solar',
  },
  GO_TRACK: {
    name: 'GO Track',
    value: 'track',
  },
  GO_CARGO_VIEW: {
    name: 'GO Cargo View',
    value: 'cargo view',
  },
  GO_CARGO_MONITOR: {
    name: 'GO Cargo Monitor',
    value: 'cargo monitor',
  },
  TRU: {
    name: 'TRU',
    value: 'tru',
  },
};

