export const fetchVehicleDetailDataApi = vinNumber =>
  `{
    vehicleDetail: vehicleDetailV2(vin: "${vinNumber}") {
      id
      vin
      trailerHealthStatus
      connectivityStatus
      extMfgrSerialNum
      recoveryMode
      baseProductType
      goProductType
      isThermoking
      subscriptionStartDate
      subscriptionEndDate
      hasSolarPanels
      installedSensorTypes {
        id
        longName
      }
      sensorBoxMfgr {
        id
        shortName
        longName
      }
      deviceType {
        id
        description
      }
      withinCustomerGeofenceNames
      customerVehicles {
        extCustomerVehicleId
      }
      vehicleType {
        id
        shortName
        longName
      }
      vehicleMfgr {
        id
        shortName
        longName
      }
      specsV2
      ageInDays
      firmwareDetails {
        id
        versions
        sensorBoxId
        sensorBoxMfgrId
        activeFrom
      }
      thermoKingCommands{
        status
        commandCompleted
        errorType
        updated
        created
        completedResponseReceived
        commandValue
        ackResponseReceived
      }
      sensorDiagnostics {
        thermoKingDiagnostics {
          lastCommunicationAt
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        reeferStatusDiagnostics {
          powerOn
          unitModeDetail
        }
        reeferFuelLevelDiagnostics {
          fuelLevel
          fuelTankSize
        }
        reeferTemperatureSensorsDiagnostics {
          indSensor1
          indSensor2
          indSensor3
          indSensor4
          indSensor5
          indSensor6
          ambientTemperature
          humidity
        }
        reeferZoneTemperaturesDiagnostics {
          zone1Active
          zone2Active
          zone3Active
          setPoint1
          setPoint2
          setPoint3
          returnAir1
          returnAir2
          returnAir3
          dischargeAir1
          dischargeAir2
          dischargeAir3
        }
        reeferHoursDiagnostics {
          engineHours
          totalHours
          electricalHours
        }
        reeferBatteryDiagnostics {
          voltage
        }
        reeferFaultDiagnostics {
          faults {
            id
            code
            description
            operatorAction
          }
        }
        tirePressureDiagnostics {
          frontLeftWheel {
            portATirePressure
            portBTirePressure
            portATirePressureThresholdDetectionStatus
            portBTirePressureThresholdDetectionStatus
            portATireLeakThresholdDetectionStatus
            portBTireLeakThresholdDetectionStatus
          }
          frontRightWheel {
            portATirePressure
            portBTirePressure
            portATirePressureThresholdDetectionStatus
            portBTirePressureThresholdDetectionStatus
            portATireLeakThresholdDetectionStatus
            portBTireLeakThresholdDetectionStatus
          }
          rearLeftWheel {
            portATirePressure
            portBTirePressure
            portATirePressureThresholdDetectionStatus
            portBTirePressureThresholdDetectionStatus
            portATireLeakThresholdDetectionStatus
            portBTireLeakThresholdDetectionStatus
          }
          rearRightWheel {
            portATirePressure
            portBTirePressure
            portATirePressureThresholdDetectionStatus
            portBTirePressureThresholdDetectionStatus
            portATireLeakThresholdDetectionStatus
            portBTireLeakThresholdDetectionStatus
          }
          lastCommunicationAt
          displayLastCommunicationAt
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        wheelEndDiagnostics {
          frontLeftWheel {
            wheelSpeed
            wheelEndTemperature
            wheelEndTemperatureCelsius
            wheelEndTemperatureFahrenheit
            wheelEndTemperatureThresholdDetection
            wheelEndSensorElectricalFault
          }
          frontRightWheel {
            wheelSpeed
            wheelEndTemperature
            wheelEndTemperatureCelsius
            wheelEndTemperatureFahrenheit
            wheelEndTemperatureThresholdDetection
            wheelEndSensorElectricalFault
          }
          rearLeftWheel {
            wheelSpeed
            wheelEndTemperature
            wheelEndTemperatureCelsius
            wheelEndTemperatureFahrenheit
            wheelEndTemperatureThresholdDetection
            wheelEndSensorElectricalFault
          }
          rearRightWheel {
            wheelSpeed
            wheelEndTemperature
            wheelEndTemperatureFahrenheit
            wheelEndTemperatureThresholdDetection
            wheelEndSensorElectricalFault
          }
          lastCommunicationAt
          displayLastCommunicationAt
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        backupBatteryDiagnostics {
          healthStatus
          backupBatteryMillivolts
          lastCommunicationAt
          displayLastCommunicationAt
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        odometerDiagnostics {
          absOdometerDiagnostic {
            lastCommunicationAt
            displayMiles
            installationStabilityStatus {
              status
              stableAt
              sensorInstallationStatus
              sensorInstallationStatusReason
              reportAge
              sensorInstallationStatusColor
            }
          }
          gpsOdometerDiagnostic {
            lastCommunicationAt
            displayMiles
            displayLastCommunicationAt
            installationStabilityStatus {
              status
              stableAt
              sensorInstallationStatus
              sensorInstallationStatusReason
              reportAge
              sensorInstallationStatusColor
            }
          }
        }
        gpsDiagnostics {
          lastCommunicationAt
          speed
          vehicleAddress {
            displayAddressLine1
            displayCityStateAndZip
            location {
              lat
              lng
            }
          }
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        tetherStatusDiagnostics {
          lastCommunicationAt
          isTethered
          liveUntetheredDurationMs
          untetheredSince
          tetheredSince
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        bogieWeightDiagnostics {
          lastCommunicationAt
          weightLbs
          isLoaded
          cargoWeightOnBogie
          loadStatusIntegrity
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        tireInflationStatusDiagnostic {
          lastCommunicationAt
          status
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        doorStatusDiagnostics {
          lastCommunicationAt
          status
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        fleetPulseDeviceDiagnostics {
          healthStatus
          lastCommunicationAt
          batteryVoltage
          gpsOdometerMiles
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        absDiagnostics {
          lastCommunicationAt
          activeFaultCount
          activeFaults {
            sensorSampleId
            absFaultLevel {
              id
              description
            }
            absSeverityLevel {
              id
              level
            }
            sensorBoxMfgrFaultType {
              faultType {
                description
              }
              extDescription
            }
          }
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
        lightsDiagnostics {
          lastCommunicationAt
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
          activeBlackCircuitFaults {
            isFaultActive
            status
          }
          activeYellowCircuitFaults {
            isFaultActive
            status
          }
          activeRedCircuitFaults {
            isFaultActive
            status
          }
          activeGreenCircuitFaults {
            isFaultActive
            status
          }
          activeBrownCircuitFaults {
            isFaultActive
            status
          }
        }
        tseDiagnostics {
          tseSystemHighestFaultLevel
          lastCommunicationAt
          frontLeft {
            highestWheelEndFaultLevel
            locationDisplay
            highestFaultsDescription
          }
          frontRight {
            highestWheelEndFaultLevel
            locationDisplay
            highestFaultsDescription
          }
          middleLeft {
            highestWheelEndFaultLevel
            locationDisplay
            highestFaultsDescription
          }
          middleRight {
            highestWheelEndFaultLevel
            locationDisplay
            highestFaultsDescription
          }
          installationStabilityStatus {
            status
            stableAt
            sensorInstallationStatus
            sensorInstallationStatusReason
            reportAge
            sensorInstallationStatusColor
          }
        }
      }
      vehiclePreventativeMaintenanceTaskDistanceSchedules {
        frequency
        tolerance
        recurrenceCount
        vehiclePreventativeMaintenanceTask {
          id
          description
        }
        measurementUnit {
          id
          name
          measurementType {
            id
            name
          }
          measurementSystem {
            id
            name
          }
        }
      }
      vehiclePreventativeMaintenanceTaskAgeSchedules {
        frequency
        tolerance
        recurrenceCount
        vehiclePreventativeMaintenanceTask {
          id
          description
        }
        measurementUnit {
          id
          name
          measurementType {
            id
            name
          }
          measurementSystem {
            id
            name
          }
        }
      }
      vehicleSensorBoxes {
        id,
        sensorBox {
          id,
          sensors {
            id,
            lastUpdated,
            sensorType {
              id,
              longName
            }
            sensorSamples {
              id,
              reading,
              recorded
              faultSensorSample {
                id,
                active,
                sensorBoxMfgrFaultType {
                  id, extDescription,
                  faultType {
                    id,
                    description
                  }
                }
              }
            }
          }
        }
      }
      vehicleInspections {
        id
        created
        inspectionDate
        notes
        vehicleInspectionTaskResults {
          id
          vehicleInspectionTaskResultType {
            id
            name
          }
          vehicleInspectionTaskSchedule {
            id
            active
            vehicleTripStage {
              id
              name
            }
            vehicleInspectionTask {
              id
              details
            }
          }
        }
      }
    }
  }`;
