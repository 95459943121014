import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-tippy/dist/tippy.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ReactGA from 'react-ga4';
import { sendPageViewEvent } from './utils/gaUtils';
import { findPageTitleByPathname } from './constants/enums/fpPagesEnum';

Sentry.init({
  dsn: 'https://3c979283674f4ab3a3875c97621c6ab5@sentry.io/1518013',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const gaFlagEnabled = process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true';
if (gaFlagEnabled) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

window.REACT_APP_GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

sendPageViewEvent({ page: window.location?.pathname, title: findPageTitleByPathname(window.location.pathname) });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
