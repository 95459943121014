export default {
  WITHIN_LAST_24_HRS: {
    id: 1,
    key: 'WITHIN_LAST_24_HRS',
    name: 'withinLast24hrs',
  },
  LAST_24_HRS: {
    id: 2,
    key: 'LAST_24_HRS',
    name: 'last24hrs',
  },
  LAST_30_DAYS: {
    id: 3,
    key: 'LAST_30_DAYS',
    name: 'last30days',
  },
  LAST_2_DAYS: {
    id: 4,
    key: 'LAST_2_DAYS',
    name: 'last2days',
  },
  LAST_10_DAYS: {
    id: 5,
    key: 'LAST_10_DAYS',
    name: 'last10days',
  },
};
