export default {
  companyName: 'FleetPulse',
  betaMessage: 'BETA',
  login: 'Log In',
  continueLogin: 'Continue Login',
  username: 'Username',
  password: 'Password',
  loginMessage: 'Log in with your username.',
  loadingMessage: 'Veryfying your credentials...',
  errorUserdata: 'Incorrect username or password.',
  keepMeLoggedIn: 'Keep me Logged in',
  logout: 'Log out',
  userManagement: 'User Management',
  trailerManagement: 'Trailer Management',
  subscriptionManagement: 'Subscription Management',
  groupManagement: 'Group Management',
  customerManagement: 'Customer Management',
  otaManagement: 'OTA Management',
  vinTransfer: 'VIN Transfer',
  developerMessageManagement: 'Developer Message Management',
  home: 'Home',
  tracking: 'Tracking',
  // dashboard: 'Dashboard',
  reporting: 'REPORTING',
  reportCSV: 'FleetPulse_30DayReportByVIN_MMDDYYYY.xlsx',
  help: 'HELP',
  alertDefaultMessage: 'An error has occured, please try again',
  warning: 'warning',
  search: 'Search VIN or Unit ID',
  searchOnlyByVin: 'Search VIN',
  geofenceSearchPlaceholder: 'Search Geofence Name',
  filter: 'Filter',
  filterLabel: 'FILTER',
  typeLabel: 'TYPE',
  dryBox: 'Dry',
  reeferBox: 'Reefer',
  flatBox: 'Flat',
  statusLabel: 'STATUS',
  untetheredBox: 'Untethered',
  tetheredBox: 'Tethered',
  bogieLabel: 'CARGO WT ON BOGIE (lbs)',
  alertsLabel: 'REPORTED FAULTS',
  healthyTrailers: 'Show only healthy trailers',
  applyButton: 'Apply',
  clearButton: 'Clear',
  clearFilters: 'xClear Filters',
  truckWeightTitle: 'Bogie Wt',
  truckStatusTitle: 'Status',
  labelTrailerId: 'VIN',
  labelCustomerTrailerId: 'Unit ID',
  labelTrailerType: 'TYPE',
  labelWeight: 'BOGIE WT',
  labelStatus: 'STATUS',
  filterLabelAll: 'All',
  filterLabelTethered: 'Tethered',
  filterLabelUntethered: 'Untethered',
  gsTrailer: 'G & S Trailer',
  advantEdge: 'AdvantEDGE',
  parts: 'Parts',
  sales: 'Sales',
  service: 'Service',
  mobileService: 'Mobile Service',
  refeerService: 'Reefer Service',
  forgotPassword: 'Forgot/Change Password',
  termsOfUse: 'Terms and Conditions',
  termsOfUseAgreement: 'By logging in or creating an account you agree to the FleetPulse ',
  termsOfUsePDF: 'FleetPulse Subscription Services Terms and Conditions v2.pdf',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyPdf: 'Trailer Telematics Inc. - Online Privacy Policy.pdf',
  email: 'Email',
  emailAddress: 'Email Address',
  enterUsername: 'Enter Your Username',
  enterEmail: 'Enter Your Email Address',
  enterUsernameDescription: 'If an account exists for this address, you will receive a one-time reset password code in your email.',
  continue: 'Submit',
  cancel: 'Cancel',
  userFound: 'is found. Continue with the reset password process.',
  userNotFound: 'User Not Found!',
  getCode: 'Get Confirmation Code',
  resetPassword: 'Reset Password',
  resetPasswordInfo: 'Reset Your Password',
  verificationCode: 'Unique Password Code',
  newPassword: 'Enter New Password',
  confirmPassword: 'Confirm New Password',
  changePassword: 'Submit',
  passwordResetSuccess: 'Your password has been reset successfully.',
  goToLogin: 'Go to Login Page.',
  specSheetModal: 'Spec Sheet',
  passwordMismatch: 'The new password and confirmation password do not match.',
  gpsLastUpdated: 'GPS Signal Last Received',
  lastUpdated: 'Last Updated',
  door: 'Door',
  lights: 'Lights',
  brake: 'Brake',
  clearance: 'Clearance,Side,ID',
  leftSignal: 'Left Signal',
  rightSignal: 'Right Signal/Hazards',
  tail: 'Tail,Clearance',
  brakes: 'Brakes',
  frontRoad: 'Front, Road',
  frontCurb: 'Front, Curb',
  rearRoad: 'Rear, Road',
  rearCurb: 'Rear, Curb',
  voltage: 'Voltage',
  tires: 'Tires ',
  tireInflation: 'Tire Inflation System',
  wheelTemp: 'Wheel Temp',
  faults: 'Faults',
  noFaults: 'No Faults Reported',
  vin: 'VIN',
  unitID: 'Unit ID',
  id: 'ID',
  type: 'Type',
  length: 'Length',
  axle: 'Axle',
  odometer: 'Odometer',
  dryVan: 'Dry Van',
  flat: 'Flat',
  reefer: 'Reefer',
  tandem: 'Tandem',
  product: 'Product',
  mfgPlant: 'Mfg Plant',
  year: 'Year',
  pckgOptions: 'Pckg Options',
  serialPlate: 'Serial Plate',
  height: 'Height',
  width: 'Width',
  upperCoupler: 'Upper Coupler',
  kingpin: 'Kingpin',
  landingGear: 'Landing Gear',
  wedge: 'Wedge',
  undercarriage: 'Undercarriage',
  misc: 'Misc',
  recentInspections: 'Recent Inspections',
  minWeight: 'Min',
  maxWeight: 'Max',
  geofence: 'Geofence: ',
  notPumping: 'Not Pumping',
  pumpingIntermittently: 'Intermittent Pumping',
  pumpingExcessively: 'Pumping Excessively',
  preventativeMaintenance: 'Preventative Maintenance Due',
  alerts: 'w/ Alerts',
  alertFree: 'Alert-free',
  radius: 'radius',
  noPreventativeMaintenance: 'No preventative maintenance due',
  cargoMonitor: 'Cargo Monitor',
  view: 'View',
  greatDaneLocations: 'Great Dane locations',
  myLocations: 'My locations',
  bogieWeightText: 'Total weight (lbs) on the trailer axles. This is calculated by measuring the amount of pressure in the air suspension.',
  odometerText: 'Odometer: Mileage is calculated through the ABS unit measuring wheel revolution using the tire circumference.',
  lightsMainText:
    'A status code will persist for ten minutes and then be automatically cleared as the system assumes the trailer is now functioning normally, this is known as a status reset.',
  brakesText: 'Faults received from the trailer’s ABS system.',
  batteryText: 'Current voltage of the trailer battery.',
  cargoMonitorText: 'Images of the inside taken. Fullness level estimates percentage of space used in the trailer.',
  gpsLastUpdatedText:
    'A trailer’s location based on three valid satellite coordinates. While tethered, a location is sent every five minutes. While untethered, the location is sent once every 24 hours.',
  configGeofenceAreas: 'My geofences',
  preventativeMaintenanceText:
    'Default recommended service intervals based on Great Dane’s suggested trailer maintenance schedule unless unique intervals are specified.',
  recentInspectionsText: 'Pre and post trip inspections logged through the FleetPulse mobile application.',
  speecSheetText: 'Trailer specifications at the time of manufacturing.',
  tetherText: `Tethered: Trailer is receiving power from the tractor. \nUntethered: Trailer is not receiving power from the tractor.`,
  doorText: `Open: Magnetic switch on at least one door is open. \nClosed: Magnetic switch on all doors are closed.`,
  tiresText: `Stable: Tire Inflation System is not active.\nUnstable: Tire Inflation System has been active for at least 10 minutes or has been unplugged.`,
  loadedText:
    'Status: Loaded or unloaded determination provided based on the cargo weight on the bogie. \nCargo Weight on Bogie: The incremental cargo weight that is sitting on the bogie.',
  fullnesLevelText:
    'Fullness level estimates the percentage of space currently used in the trailer. Pressing View will open the most recent image from inside the trailer.',
  unknownTooltipText:
    'Status of the branch is not yet known. The baseline is currently being established. Occurs after status reset or when trailer is tethered.',
  highCurrentTooltipText: 'A higher-than-expected current anomaly has been detected on the branch, possibly indicating a short circuit has occurred.',
  lowCurrentTooltipText: 'A lower-than-expected current anomaly has been detected on the branch, possibly indicating that a lamp has gone out.',
  goodTooltipText: 'No anomalies have been detected since status reset.',
  intermittentTooltipText: 'There are intermittent failures detected on the branch. This could indicate a loose electrical connection.',
  unloadedBox: 'Unloaded',
  loadedBox: 'Loaded',
  faq: 'Help',
  alertsNavTitle: 'Alerts',
  insights: 'Insights',
  alertsActivityFeed: 'Alerts Activity Feed',
  alertManagement: 'Alert Management',
  alertNotificationFactor: 'was caused by an',
  oneTimeOnly: 'Only one alert notification for the duration an alert event occurs',
  every24Hours: 'One notification every 24 hours for the duration an alert event occurs',
  noAlertsCreated: 'No alerts created yet, please select a sensor to begin.',
  absFaultTroubleshooting: 'ABS Fault Troubleshooting',
  specs: 'Specifications',
  unsubscribe: 'Unsubscribe',
  unsubscriptionFromOneEmail: 'You have succesfully unsubscribed from FleetPulse emails for the alert name',
  unsubscriptionFromAllEmails: 'You have succesfully unsubscribed from all FleetPulse emails.',
  unsubscriptionFromCustomerInstallations: 'You have successfully unsubscribed from FleetPulse emails for Daily Customer Installations',
  unsubscriptionFromSingleReport: 'You have succesfully unsubscribed from FleetPulse emails for the report name',
  viewAdditionalSpecs: 'View Additional Specifications',
  noAbsFaults: 'No ABS faults have occurred for this trailer.',
  productSpecsTitle: 'Product',
  mfgPlantLabel: 'Manufacturing Plant',
  yearLabel: 'Model Year',
  geoDimensionsSpecsTitle: 'Geometric Dimensions',
  pckgOptionsLabel: 'Packaged Options',
  serialPlateLabel: 'Serial Plate',
  heightLabel: 'Overall Height',
  lengthLabel: 'Overall Length',
  widthLabel: 'Overall Width',
  upperCouplerLabel: 'Upper Coupler Height',
  kingpinLabel: 'Kingpin Location',
  landingGearLabel: 'Landing Gear Location',
  wedgeLabel: 'Wedge',
  undercarriageLabel: 'Undercarriage Design',
  miscLabel: 'Miscellaneous Text (TABA)',
  wrongVinInput: 'Please select a VIN from the drop down list.',
  passwordNeedsTo: 'Your password needs to:',
  passwordNotMatching: 'Passwords must match. Please update entered information.',
  passwordNoSpecCharacter: 'Your password must contain special characters (~!@#$%^&*).',
  passwordNoUpperLowerCase: 'Your password must contain one lowercase and one uppercase character.',
  passwordNoNumbers: 'Your password must contain one number.',
  passwordNotLongEnough: 'Password must be longer than 8 characters.',
  geofenceModalTipText: `Tip: Trailer should be powered by the tractor 7-way for best results. When powered, trailers report GPS every 5 minutes.`,
  geofenceModalLink: `Click here to review best practices.`,
  createAccount: 'Create Account',
  enterPassword: 'Enter Password',
  confirmPass: 'Confirm Password',
  createAccountDescriptionText: `Only Authorized Great Dane Aftermarket locations are currently permitted to create a technician account through this process.
  \nIf you are a FleetPulse customer, please reach out to `,
  notAuthorized: 'Not Authorized',
  notAuthorizedDescText:
    'This email is not authorized for technician access.  \nIf you are an Authorized Great Dane Aftermarket employee and feel this is an error, please reach out to ',
  notAuthorizedEmail: 'support@fleetpulse.com',
  supportEmail: 'support@fleetpulse.com',
  startOver: 'Start Over',
  accountCreated: 'Account Created',
  accountCreatedDesc: `Please check your email to verify your email address and gain access to FleetPulse. `,
  accountCreatedDescSecPart: `\nIf you did not receive an email or have any issues with this process please contact `,
  confirmUserSuccess: 'You now have access to FleetPulse. You can log in through the browser on your computer or the FleetPulse mobile application.',
  invalidConfirmationLinkFirstPart: 'The provided confirmation link is invalid. Please contract ',
  contactEmail: 'support@fleetpulse.com',
  invalidConfirmationLinkSecondPart: ' for more information.',
  googlePlayLegalText: 'Google Play and the Google Play logo are trademarks of Google LLC.',
  appStoreLegalText: 'App Store® and Apple® are registered trademarks of Apple Inc.',
  mapSearchErrorText:
    'This trailer is either not close enough to your location, or the customer has requested their trailers not be accessible for technicians. For questions, please reach out to the FleetPulse team at ',
  userNotVerifiedError: 'Account setup not complete. Please check your email for verification link.',
  userNotValidCredentials: 'not authorized or username/password incorrect',
  lastValidGPS: 'Last valid GPS Report used to obtain location.',
  trailerSpecsSheet: 'Trailer Specifications Sheet',
  notValidEmailText: 'Email entered is not valid.',
  selectCustomAlert: 'Please select custom alert to add additional alert criteria.',
  unsubscribeError: 'Error: Please click the link in the email or make sure it is copied exactly.',
  addToUsersTo: 'Add Users To Group',
  removeUsersFrom: 'Remove Users From Group',
  addUsers: 'Add Users',
  removeUsers: 'Remove Users',
  atLeastOneUserAssigned: 'You must assign user to at least one of the following groups',
  atLeastOneCustomerSelected: 'You need to select a customer in order to change group assignments or to edit groups',
  customerAdminHasNoGroups:
    'Your organization has no custom trailer groups.   \n\nAdd a group on the Groups Management page and optionally add trailers to it in order to filter the trailers on the map by group.',
  customerHasNoGroups: 'Your organization has no custom trailer groups.',
  allVehiclesActiveText: 'Alert for all trailer in this account',
  notificationManagement: 'Notification Management',
  currentNotifications: 'Current Notifications',
  notFoundTitle: 'Page was not found',
  notFoundMessage: 'Sorry, the page you are trying to view does not exist.',
  notFoundButtonMessage: 'Go to Tracking Page',
  createReportTitle: 'Create Report',
  createReportSuccess: 'Successfully created a new report',
  createReportFail: 'Report could not be created!',
  updateReportTitle: 'Update Report',
  updateReportSuccess: 'Successfully updated report',
  updateReportFail: 'Report could not be updated!',
  deleteReportTitle: 'Delete Report',
  deleteReportSuccess: 'Report was successfully deleted!',
  deleteReportFail: 'Report could not be deleted!',
  loadReportTitle: 'Load Report',
  loadReportSuccess: 'Report was successfully loaded!',
  loadReportFail: 'Report could not be loaded!',
  createAlertTitle: 'Create Alert',
  createAlertSuccess: 'Successfully created a new alert',
  createAlertFail: 'Alert could not be created!',
  updateAlertTitle: 'Update Alert',
  updateAlertSuccess: 'Successfully updated alert',
  updateAlertFail: 'Alert could not be updated!',
  deleteAlertTitle: 'Delete Alert',
  deleteAlertSuccess: 'Alert was successfully deleted!',
  deleteAlertFail: 'Alert could not be deleted!',
  loadAlertTitle: 'Load Alert',
  loadAlertSuccess: 'Alert was successfully loaded!',
  loadAlertFail: 'Alert could not be loaded!',
  createUserTitle: 'Create New User',
  createUserFail: 'Error while creating user: ',
  createUserSuccess: 'Successfully created user: ',
  editUserTitle: 'Edit User',
  editUserFail: 'Error while editing user: ',
  editUserSuccess: 'Successfully updated user: ',
  deleteUserTitle: 'Delete User',
  deleteUserFail: 'Error while deleting user: ',
  deleteUserSuccess: 'Successfully deleted user: ',
  duplicateUnitIdMessage: 'FleetPulse cannot accept this Unit ID because it is already in use by another trailer in your fleet',
  duplicateCargoCamIdMessage: 'FleetPulse cannot accept this Cargo Cam ID because it is already in use by another trailer',
  freightAgreementMessage:
    'FleetPulse Freight is your personalized load board and matching tool to help you find freight. FleetPulse Freight uses Machine Learning and Artificial Intelligence to make the freight market more efficient for carriers and the brokerages that provide them with freight.    \n\nIn order to access FleetPulse Freight you must accept the User Agreement. Additionally, if you wish to utilize recommendation services then an Account Admin must accept Data Agreement as well. Please read agreement and accept below to proceed. By agreeing you are acknowledging you have read and agree to each selected agreement.',
  dataAgreementForCustomers: 'Only Account Admins are able to accept the data agreement. Please reach out to your respective admin to accept the agreement.',
  userMustAcceptTermsOfService: 'User must accept new terms of Service',
  termsOfServiceCancelled: 'Terms of use must be accepted to use the FleetPulse application.  Please login and accept terms of use.',
  fpTermsOfUseFileName: 'FleetPulse%20Services%20End%20User%20Agreement_4827-9024-6757%20v.1.pdf',
  tkUserBadRequest: 'Command failed please try again later',
  tkUserNoAccess: 'You do not have access to send a command',
  valueShouldBeEmptyStringOrNumber: 'The value should be either an empty string or a number!',
  requestCompletedSuccessfully: 'Request completed successfully!',
  unknownErrorOccurred: 'An unknown error occurred!',
};
