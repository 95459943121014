// Delete
export const FOO = 'FOO';

export const UNLOADED_BOGIE_WEIGHT = 10260;

// Tracking Dashboard
export const RECEIVE_TRACKING_DASHBOARD_DATA_REFRESH = 'RECEIVE_TRACKING_DASHBOARD_DATA_REFRESH';
export const TOGGLE_TRACKING_DASHBOARD_LOADING = 'TOGGLE_TRACKING_DASHBOARD_LOADING';
export const FETCH_TRACKING_DASHBOARD_DATA_FAIL = 'FETCH_TRACKING_DASHBOARD_DATA_FAIL';
export const TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL = 'TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL';

export const TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW = 'TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW';

export const TOGGLE_GEOFENCE = 'TOGGLE_GEOFENCE';
export const TOGGLE_TRAFFIC_OVERLAY = 'TOGGLE_TRAFFIC_OVERLAY';
export const TOGGLE_WEATHER_CONDITIONS = 'TOGGLE_WEATHER_CONDITIONS';

export const APPLY_FILTER = 'APPLY_FILTER';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CHANGE_WEIGHT_FILTER = 'CHANGE_WEIGHT_FILTER';
export const SEARCH_TRUCKS = 'SEARCH_TRUCKS';
export const SHOW_SPECS_MENU = ' SHOW_SPECS_MENU';
export const SHOW_COLLAPSE = 'SHOW_COLLAPSE';
export const SHOW_PREVENTATIVE = 'SHOW_PREVENTATIVE';
export const SHOW_PREVENTATIVE_COLLAPSE = 'SHOW_PREVENTATIVE_COLLAPSE';
export const HIDE_MODAL_COLLAPSES = 'HIDE_MODAL_COLLAPSES';

export const FILTER_TRUCKS = 'FILTER_TRUCKS';

export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

export const TOGGLE_SHOW_SHAPES_VIEW = 'TOGGLE_SHOW_SHAPES_VIEW';

export const CREATE_NEW_SHAPE = 'CREATE_NEW_SHAPE';

export const TOGGLE_ALERTS_STATE = 'TOGGLE_ALERTS_STATE';

export const TOGGLE_USER_PANEL = 'TOGGLE_USER_PANEL';
export const DEFAULT_MAP_ZOOM = 5;
export const GMAP_SCRIPT_ID = 'google-map-script';
export const REFRESH_DATA_INTERVAL_MS = 30000;

// NavigationDrawer
export const TOGGLE_SENSOR_DETAILS_SCREEN = 'TOGGLE_SENSOR_DETAILS_SCREEN';
export const OPEN_NAVIGATION_DRAWER = 'OPEN_NAVIGATION_DRAWER';
export const CLOSE_NAVIGATION_DRAWER = 'CLOSE_NAVIGATION_DRAWER';

export const GET_SENSOR_ORDER_DATA = 'GET_SENSOR_ORDER_DATA';
export const TOGGLE_SENSOR_ORDER_LOADING = 'TOGGLE_SENSOR_ORDER_LOADING';
export const FETCH_SENSOR_ORDER_FAIL = 'FETCH_SENSOR_ORDER_FAIL';

export const SET_SENSOR_ORDER_DATA_SUCCESS = 'SET_SENSOR_ORDER_DATA_SUCCESS';
export const SET_SENSOR_ORDER_DATA_FAIL = 'SET_SENSOR_ORDER_DATA_FAIL';
export const TOGGLE_SET_SENSOR_ORDER_LOADING = 'TOGGLE_SET_SENSOR_ORDER_LOADING';
export const HANDLE_SENSOR_TYPE_DRAG_END = 'HANDLE_SENSOR_TYPE_DRAG_END';

// Login
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const TOGGLE_LOGIN_LOADING = 'TOGGLE_LOGIN_LOADING';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';
export const CLEAR_LOGIN_DATA = 'CLEAR_LOGIN_DATA';

export const RECEIVE_ME_DATA = 'RECEIVE_ME_DATA';
export const TOGGLE_ME_DATA_LOADING = 'TOGGLE_ME_DATA_LOADING';
export const FETCH_ME_DATA_FAIL = 'FETCH_ME_DATA_FAIL';

export const SET_PARTIAL_USER_DATA = 'SET_PARTIAL_USER_DATA';
export const SET_TERMS_OF_USE_DIALOG_STATUS = 'SET_TERMS_OF_USE_DIALOG_STATUS';
export const SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS = 'SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS';

export const SET_PASSWORD_EXPIRATION = 'SET_PASSWORD_EXPIRATION';
export const CONTINUE_LOGIN = 'CONTINUE_LOGIN';

// Token Validation
export const SET_IS_USER_AUTHENTICATED = 'SET_IS_USER_AUTHENTICATED';

// App
export const RECEIVE_APP_DATA = 'RECEIVE_APP_DATA';
export const FETCH_APP_DATA_FAIL = 'FETCH_APP_DATA_FAIL';
export const TOGGLE_APP_DATA_LOADING = 'TOGGLE_APP_DATA_LOADING';
export const TOGGLE_RESET_PASSWORD_LOADING = 'TOGGLE_RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRMATION_CODE_SENT = 'RESET_PASSWORD_CONFIRMATION_CODE_SENT';
export const SET_USERNAME_FIELD = 'SET_USERNAME_FIELD';
export const CONVERT_USERNAME_FIELD_TO_LOWERCASE = 'CONVERT_USERNAME_FIELD_TO_LOWERCASE';
export const SET_NEW_PASSWORD_VALUE = 'SET_NEW_PASSWORD_VALUE';
export const SET_CONFIRM_PASSWORD_VALUE = 'SET_CONFIRM_PASSWORD_VALUE';
export const CHECK_PASSWORD_VALIDATION = 'CHECK_PASSWORD_VALIDATION';
export const TOGGLE_RESET_PASSWORDS_TYPE = 'TOGGLE_RESET_PASSWORDS_TYPE';
export const SET_LOGIN_FAIL_MESSAGE = 'SET_LOGIN_FAIL_MESSAGE';

// Create Account
export const SET_CREATE_ACCOUNT_EMAIL = 'SET_CREATE_ACCOUNT_EMAIL';
export const SET_CREATE_ACCOUNT_PASSWORD = 'SET_CREATE_ACCOUNT_PASSWORD';
export const SET_CREATE_ACCOUNT_CONFIRM_PASSWORD = 'SET_CREATE_ACCOUNT_CONFIRM_PASSWORD';
export const TOGGLE_CREATE_ACCOUNT_PASSWORDS = 'TOGGLE_CREATE_ACCOUNT_PASSWORDS';
export const TRIGGER_CREATE_ACCOUNT_STATUS_PAGE = 'TRIGGER_CREATE_ACCOUNT_STATUS_PAGE';
export const TOGGLE_CREATE_ACCOUNT_LOADING = 'TOGGLE_CREATE_ACCOUNT_LOADING';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAIL = 'CREATE_ACCOUNT_FAIL';
export const RESET_CREATE_ACCOUNT_FIELDS = 'RESET_CREATE_ACCOUNT_FIELDS';

// Date Time Format Strings
export const formatTimestamp = 'MM/DD/YY HH:mm z';
export const widgetTimestampsFormat = 'M/D/YY h:mm A z';
export const lastUpdatedTimestampFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const lastReportedTimestampFormat = 'MM/DD/YYYY hh:mm A z';
export const csvNameTimestamp = 'MM-DD-YYYY';
export const dateFormat = 'MM/DD/YYYY';
export const gaTimestampFormat = 'M/D/YY h:mm A';
export const FIVE_MINUTES_IN_SECONDS = 300;
export const FIFTYFIVE_MINUTES_IN_SECONDS = 3300;

export const calculatedValues = 'Calculated Values';

// DashboardFilters
export const TOGGLE_DASHBOARD_FILTERS = 'TOGGLE_DASHBOARD_FILTERS';
export const SET_DASHBOARD_FILTERS_STATUS = 'SET_DASHBOARD_FILTERS_STATUS';

export const SELECT_ABS_NO_FAULTS_FILTER = 'SELECT_ABS_NO_FAULTS_FILTER';
export const SELECT_ABS_FAULTS_FILTER = 'SELECT_ABS_FAULTS_FILTER';
export const SELECT_ABS_ALERTS_FILTER = 'SELECT_ABS_ALERTS_FILTER';

export const SELECT_TIS_NO_FAULTS_FILTER = 'SELECT_TIS_NO_FAULTS_FILTER';
export const SELECT_TIS_FAULTS_FILTER = 'SELECT_TIS_FAULTS_FILTER';
export const SELECT_TIS_ALERTS_FILTER = 'SELECT_TIS_ALERTS_FILTER';

export const SELECT_BATTERY_NO_FAULTS_FILTER = 'SELECT_BATTERY_NO_FAULTS_FILTER';
export const SELECT_BATTERY_FAULTS_FILTER = 'SELECT_BATTERY_FAULTS_FILTER';
export const SELECT_BATTERY_ALERTS_FILTER = 'SELECT_BATTERY_ALERTS_FILTER';

export const SELECT_LIGHT_NO_FAULTS_FILTER = 'SELECT_LIGHT_NO_FAULTS_FILTER';
export const SELECT_LIGHT_FAULTS_FILTER = 'SELECT_LIGHT_FAULTS_FILTER';
export const SELECT_LIGHT_ALERTS_FILTER = 'SELECT_LIGHT_ALERTS_FILTER';

export const SELECT_TETHER_STATUS = 'SELECT_TETHER_STATUS';
export const SELECT_LOAD_STATUS = 'SELECT_LOAD_STATUS';
export const SELECT_PRODUCT_TYPE_STATUSES = 'SELECT_PRODUCT_TYPE_STATUSES';
export const SELECT_ITEM_TYPE_STATUSES = 'SELECT_ITEM_TYPE_STATUSES';
export const SELECT_CONNECTIVITY_STATUS = 'SELECT_CONNECTIVITY_STATUS';

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const SET_FAULT_TOTALS = 'SET_FAULT_TOTALS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_SELECTED_GROUPS = 'SET_SELECTED_GROUPS';
export const GET_FILTER_COOKIE = 'GET_FILTER_COOKIE';

// OverlayBar
export const TOGGLE_OVERLAY_BAR = 'TOGGLE_OVERLAY_BAR';

// GET PARTS DATA
export const RECEIVE_PARTS_DATA = 'RECEIVE_PARTS_DATA';
export const TOGGLE_PARTS_DATA_LOADING = 'TOGGLE_PARTS_DATA_LOADING';
export const FETCH_PARTS_DATA_FAIL = 'FETCH_PARTS_DATA_FAIL';
export const TOGGLE_PARTS_IMAGE_MODAL = 'TOGGLE_PARTS_IMAGE_MODAL';
export const RECEIVE_IMAGE_MODAL_DATA = 'RECEIVE_IMAGE_MODAL_DATA';

// GET VEHICLE DETAIL DATA
export const RECEIVE_VEHICLE_DETAIL_DATA = 'RECEIVE_VEHICLE_DETAIL_DATA';
export const TOGGLE_VEHICLE_DETAIL_DATA_LOADING = 'TOGGLE_VEHICLE_DETAIL_DATA_LOADING';
export const FETCH_VEHICLE_DETAIL_DATA_FAIL = 'FETCH_VEHICLE_DETAIL_DATA_FAIL';

export const RECEIVE_VEHICLE_SPECS_DETAIL_DATA = 'RECEIVE_VEHICLE_SPECS_DETAIL_DATA';
export const TOGGLE_VEHICLE_SPECS_DETAIL_DATA_LOADING = 'TOGGLE_VEHICLE_SPECS_DETAIL_DATA_LOADING';
export const FETCH_VEHICLE_SPECS_DETAIL_DATA_FAIL = 'FETCH_VEHICLE_SPECS_DETAIL_DATA_FAIL';

export const FETCH_TRAILER_IMAGE_HISTORY_DATA = 'FETCH_TRAILER_IMAGE_HISTORY_DATA';
export const TOGGLE_TRAILER_IMAGE_HISTORY_DATA_LOADING = 'TOGGLE_TRAILER_IMAGE_HISTORY_DATA_LOADING';
export const FETCH_TRAILER_IMAGE_HISTORY_DATA_FAIL = 'FETCH_TRAILER_IMAGE_HISTORY_DATA_FAIL';
export const EMPTY_TRAILER_IMAGE_HISTORY = 'EMPTY_TRAILER_IMAGE_HISTORY';

// GET GD SERVICE CENTERS DATA
export const TOGGLE_GD_SERVICE_CENTERS = 'TOGGLE_GD_SERVICE_CENTERS';
export const RECEIVE_GD_SERVICE_DATA = 'RECEIVE_GD_SERVICE_DATA';
export const TOGGLE_GD_SERVICE_LOADING = 'TOGGLE_GD_SERVICE_LOADING';
export const FETCH_GD_SERVICE_FAIL = 'FETCH_GD_SERVICE_FAIL';
export const SET_GD_SERVICE_DATA_VISIBILITY = 'SET_GD_SERVICE_DATA_VISIBILITY';

// GET CLOSEST GD SERVICE CENTER
export const RECEIVE_CLOSEST_GD_SERVICE_DATA = 'RECEIVE_CLOSEST_GD_SERVICE_DATA';
export const TOGGLE_CLOSEST_GD_SERVICE_LOADING = 'TOGGLE_CLOSEST_GD_SERVICE_LOADING';
export const FETCH_CLOSEST_GD_SERVICE_FAIL = 'FETCH_CLOSEST_GD_SERVICE_FAIL';
export const SEND_DIAGNOSTIC_TO_SERVICE_CENTER_BEGIN = 'SEND_DIAGNOSTIC_TO_SERVICE_CENTER_BEGIN';
export const SEND_DIAGNOSTIC_TO_SERVICE_CENTER_SUCCESS = 'SEND_DIAGNOSTIC_TO_SERVICE_CENTER_SUCCESS';
export const SEND_DIAGNOSTIC_TO_SERVICE_CENTER_FAIL = 'SEND_DIAGNOSTIC_TO_SERVICE_CENTER_FAIL';
export const SHOW_SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CONFIRM = 'SHOW_SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CONFIRM';
export const OPEN_EMAIL_SENT_ALERT_DIALOG = 'OPEN_EMAIL_SENT_ALERT_DIALOG';
export const SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CANCEL = 'SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CANCEL';

// GEOFENCE
export const SAVE_GEOFENCE = 'SAVE_GEOFENCE';
export const TOGLE_GEOFENCE_EDITOR = 'TOGLE_GEOFENCE_EDITOR';
export const TOGLE_SECOND_GEOFENCE_EDITOR = 'TOGLE_SECOND_GEOFENCE_EDITOR';
export const BEGIN_CREATE_NEW_GEOFENCE = 'BEGIN_CREATE_NEW_GEOFENCE';
export const CLOSE_GEOFENCE_MODAL = 'CLOSE_GEOFENCE_MODAL';
export const BEGIN_GEOFENCE_DRAWING = 'BEGIN_GEOFENCE_DRAWING';
export const EXIT_GEOFENCE_DRAWING_MODE = 'EXIT_GEOFENCE_DRAWING_MODE';
export const SET_CURRENT_DRAWING_SHAPE = 'SET_CURRENT_DRAWING_SHAPE';
export const SET_DRAWING_MODE = 'SET_DRAWING_MODE';
export const SAVE_SHAPE_FROM_DRAWING_MODE = 'SAVE_SHAPE_FROM_DRAWING_MODE';

export const LOAD_USER_GEOFENCE_INTO_MODAL = 'LOAD_USER_GEOFENCE_INTO_MODAL';
export const UPDATE_GEOFENCE_MODAL_NAME = 'UPDATE_GEOFENCE_MODAL_NAME';
export const UPDATE_GEOFENCE_MODAL_DESCRIPTION = 'UPDATE_GEOFENCE_MODAL_DESCRIPTION';
export const UPDATE_GEOFENCE_MODAL_IS_ACTIVE = 'UPDATE_GEOFENCE_MODAL_IS_ACTIVE';

export const SET_GEOFENCE_FILTER = 'SET_GEOFENCE_FILTER';
export const TOGGLE_GEOFENCE_LIST = 'TOGGLE_GEOFENCE_LIST';

// GEOFENCES_V2
export const FETCH_USER_GEOFENCES_V2_BEGIN = 'FETCH_USER_GEOFENCES_V2_BEGIN';
export const FETCH_USER_GEOFENCES_V2_SUCCESS = 'FETCH_USER_GEOFENCES_V2_SUCCESS';
export const FETCH_USER_GEOFENCES_V2_FAIL = 'FETCH_USER_GEOFENCES_V2_FAIL';

export const CREATE_USER_GEOFENCE_BEGIN = 'CREATE_USER_GEOFENCE_BEGIN';
export const CREATE_USER_GEOFENCE_SUCCESS = 'CREATE_USER_GEOFENCE_SUCCESS';
export const CREATE_USER_GEOFENCE_FAIL = 'CREATE_USER_GEOFENCE_FAIL';

export const UPDATE_USER_GEOFENCE_BEGIN = 'UPDATE_USER_GEOFENCE_BEGIN';
export const UPDATE_USER_GEOFENCE_SUCCESS = 'UPDATE_USER_GEOFENCE_SUCCESS';
export const UPDATE_USER_GEOFENCE_FAIL = 'UPDATE_USER_GEOFENCE_FAIL';

export const DELETE_USER_GEOFENCE_BEGIN = 'DELETE_USER_GEOFENCE_BEGIN';
export const DELETE_USER_GEOFENCE_SUCCESS = 'DELETE_USER_GEOFENCE_SUCCESS';
export const DELETE_USER_GEOFENCE_FAIL = 'DELETE_USER_GEOFENCE_FAIL';
export const OPEN_CREATE_GEOFENCE_ALERT_DIALOG = 'OPEN_CREATE_GEOFENCE_ALERT_DIALOG';
export const OPEN_UPDATE_GEOFENCE_ALERT_DIALOG = 'OPEN_UPDATE_GEOFENCE_ALERT_DIALOG';
export const OPEN_DELETE_GEOFENCE_ALERT_DIALOG = 'OPEN_DELETE_GEOFENCE_ALERT_DIALOG';

// NAVIGATION SEARCH
export const TOGGLE_NAVIGATION_SEARCH_LIST = 'TOGGLE_NAVIGATION_SEARCH_LIST';
export const TOGGLE_SHOW_MORE_TRAILER_LIST = 'TOGGLE_SHOW_MORE_TRAILER_LIST';
export const TOGGLE_SHOW_MORE_GEOFENCE_LIST = 'TOGGLE_SHOW_MORE_GEOFENCE_LIST';
export const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION';
export const RESET_SEARCH_LOCATION_COORDINATES = 'RESET_SEARCH_LOCATION_COORDINATES';
export const TOGGLE_LOCATION_SEARCH = 'TOGGLE_LOCATION_SEARCH';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';

//TRACKING_HISTORY
export const TOGGLE_TRACKING_HISTORY = 'TOGGLE_TRACKING_HISTORY';
export const TOGGLE_TRACKING_HISTORY_DATA_LOADING = 'TOGGLE_TRACKING_HISTORY_DATA_LOADING';
export const FETCH_TRACKING_HISTORY_DATA_FAIL = 'FETCH_TRACKING_HISTORY_DATA_FAIL';
export const RECEIVE_TRACKING_HISTORY_DATA = 'RECEIVE_TRACKING_HISTORY_DATA';

// ALERTS
export const UNSUBSCRIBE_EMAIL_NOTIFICATION = 'UNSUBSCRIBE_EMAIL_NOTIFICATION';
export const UNSUBSCRIBE_EMAIL_NOTIFICATION_FAIL = 'UNSUBSCRIBE_EMAIL_NOTIFICATION_FAIL';
export const SET_UNSUBSCRIBE_EMAIL_NOTIFICATION_LOADING = 'SET_UNSUBSCRIBE_EMAIL_NOTIFICATION_LOADING';

export const FETCH_ABS_FAULT_TYPES_SUCCESS = 'FETCH_ABS_FAULT_TYPES_SUCCESS';
export const FETCH_LIGHT_FAULT_TYPES_SUCCESS = 'FETCH_LIGHT_FAULT_TYPES_SUCCESS';
export const FETCH_COMPONENT_FAULT_TYPES_SUCCESS = 'FETCH_COMPONENT_FAULT_TYPES_SUCCESS';
export const FETCH_LIGHT_FAULT_TYPES_FAIL = 'FETCH_LIGHT_FAULT_TYPES_FAIL';
export const FETCH_ABS_FAULT_TYPES_FAIL = 'FETCH_ABS_FAULT_TYPES_FAIL';
export const FETCH_COMPONENT_FAULT_TYPES_FAIL = 'FETCH_COMPONENT_FAULT_TYPES_FAIL';
export const GET_GEOFENCES_SUCCESS = 'GET_GEOFENCES_SUCCESS';
export const GET_GEOFENCES_FAIL = 'GET_GEOFENCES_FAIL';

// FEATURE TOGGLE
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

// ADDITIONAL SPECS & ABS TROUBLESHOOTING

export const SET_INITIAL_VIN_VALUE = 'SET_INITIAL_VIN_VALUE';
export const FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_BEGIN = 'FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_BEGIN';
export const FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_FAIL = 'FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_FAIL';
export const FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_SUCCESS = 'FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_SUCCESS';
export const SET_ABS_FAULT_DATE_RANGE_FILTER = 'SET_ABS_FAULT_DATE_RANGE_FILTER';
export const SET_ABS_FAULT_STATUS_FILTER = 'SET_ABS_FAULT_STATUS_FILTER';
export const CHANGE_ABS_FAULT_TYPE_FILTER = 'CHANGE_ABS_FAULT_TYPE_FILTER';

//  FAQ
export const TOGGLE_FAQ_NAVBAR_ITEM = 'TOGGLE_FAQ_NAVBAR_ITEM';

// CONFIRM USER
export const CONFIRM_USER_START = 'CONFIRM_USER_START';
export const CONFIRM_USER_FAIL = 'CONFIRM_USER_FAIL';
export const CONFIRM_USER_RESULT = 'CONFIRM_USER_RESULT';
export const TOKEN_EXPIRATION_START = 'TOKEN_EXPIRATION_START';
export const TOKEN_EXPIRATION_FAIL = 'TOKEN_EXPIRATION_FAIL';
export const TOKEN_EXPIRATION_RESULT = 'TOKEN_EXPIRATION_RESULT';

export const GOOGLE_PLAY_STORE_FP_APP_URL =
  'https://play.google.com/store/apps/details?id=com.greatdanetrailers.fleetpulse&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
export const APP_STORE_FP_APP_URL = 'https://apps.apple.com/us/app/great-dane-fleetpulse-mobile/id1410816446';

// CURRENT FLEET STATUS
export const FETCH_FLEET_STATUS_DATA_FAIL = 'FETCH_FLEET_STATUS_DATA_FAIL';
export const TOGGLE_FLEET_STATUS_LOADING = 'TOGGLE_FLEET_STATUS_LOADING';
export const RECEIVE_FLEET_STATUS_DATA = 'RECEIVE_FLEET_STATUS_DATA';
export const TOGGLE_FLEET_STATUS_FILTER = 'TOGGLE_FLEET_STATUS_FILTER';
export const TOGGLE_HIDE_FIELDS_DROPDOWN = 'TOGGLE_HIDE_FIELDS_DROPDOWN';
export const TOGGLE_FILTER_BY_FAULTS_DROPDOWN = 'TOGGLE_FILTER_BY_FAULTS_DROPDOWN';
export const TOGGLE_HIDE_FIELD_FILTER = 'TOGGLE_HIDE_FIELD_FILTER';
export const SET_BATCH_COMMAND_SCHEDULE = 'SET_BATCH_COMMAND_SCHEDULE';
export const COOKIE_EXPIRATION_DAYS = 180;
// PowerBI
export const POWER_BI_TOKEN_SET_LOADING = 'POWER_BI_TOKEN_SET_LOADING';
export const POWER_BI_TOKEN_CREATE_SUCCESS = 'POWER_BI_TOKEN_CREATE_SUCCESS';
export const POWER_BI_TOKEN_CREATE_ERROR = 'POWER_BI_TOKEN_CREATE_ERROR';

// User Management
export const SET_USER_MANAGEMENT_DATA_LOADING = 'SET_USER_MANAGEMENT_LOADING';
export const FETCH_USER_MANAGEMENT_DATA_SUCCESS = 'FETCH_USER_MANAGEMENT_DATA_SUCCESS';
export const FETCH_USER_MANAGEMENT_DATA_FAIL = 'FETCH_USER_MANAGEMENT_DATA_FAIL';

export const TOGGLE_USER_SELECT = 'TOGGLE_USER_SELECT';
export const TOGGLE_ALL_USERS_SELECT = 'TOGGLE_ALL_USERS_SELECT';
export const SEARCH_ALL_USERS = 'SEARCH_ALL_USERS';

export const OPEN_CREATE_ACCOUNT_MODAL = 'OPEN_CREATE_ACCOUNT_MODAL';

export const SET_CUSTOMERS_DATA_LOADING = 'SET_CUSTOMERS_DATA_LOADING';
export const FETCH_CUSTOMERS_DATA_FAIL = 'FETCH_CUSTOMERS_DATA_FAIL';
export const FETCH_CUSTOMERS_DATA_SUCCESS = 'FETCH_CUSTOMERS_DATA_SUCCESS';

export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';
export const SET_SELECTED_USER_TYPES = 'SET_SELECTED_USER_TYPES';
export const SET_SELECTED_CUSTOMERS = 'SET_SELECTED_CUSTOMERS';
export const SET_SELECTED_GO_CUSTOMERS = 'SET_SELECTED_GO_CUSTOMERS';
export const SET_SELECTED_ADMIN_CUSTOMERS = 'SET_SELECTED_ADMIN_CUSTOMERS';
export const SET_DISPLAY_CUSTOMERS = 'SET_DISPLAY_CUSTOMERS';
export const SET_DISPLAY_GO_CUSTOMERS = 'SET_DISPLAY_GO_CUSTOMERS';
export const SET_DISPLAY_ADMIN_CUSTOMERS = 'SET_DISPLAY_ADMIN_CUSTOMERS';

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const DISABLE_USER = 'DISABLE_USER';

export const OPEN_CREATE_NEW_USER_DIALOG = 'OPEN_CREATE_NEW_USER_DIALOG';
export const OPEN_EDIT_USER_DIALOG = 'OPEN_EDIT_USER_DIALOG';
export const OPEN_DELETE_USER_DIALOG = 'OPEN_DELETE_USER_DIALOG';
export const CLOSE_RESEND_EMAIL_MODAL = 'CLOSE_RESEND_EMAIL_MODAL';
export const SET_RESEND_EMAIL_TYPE = 'SET_RESEND_EMAIL_TYPE';

export const OPEN_USER_ALERT_DIALOG_BOX = 'OPEN_USER_ALERT_DIALOG_BOX';
export const CLOSE_USER_ALERT_DIALOG_BOX = 'CLOSE_USER_ALERT_DIALOG_BOX';

export const RESEND_EMAIL_BEGIN = 'RESEND_EMAIL_BEGIN';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'RESEND_EMAIL_FAIL';

export const SET_EDIT_USER_MODE = 'SET_EDIT_USER_MODE';

export const MILLISECONDS_PER_DAY = 86400000;
export const DEFAULT_ALERT_NOTIFICATION_RATE_LIMIT_VALUE_IN_MS = 0;
export const CAUTION_STATUS_DAYS_LIMIT = 2;
export const CRITICAL_STATUS_DAYS_LIMIT = 10;
export const DOOR_STATUS_CAUTION_DAYS_LIMIT = 30;
export const englishListFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

// Activity Log
export const SET_ACTIVITY_LOG_DATA_LOADING = 'SET_ACTIVITY_LOG_DATA_LOADING';
export const FETCH_ACTIVITY_LOG_DATA_SUCCESS = 'FETCH_ACTIVITY_LOG_DATA_SUCCESS';
export const FETCH_ACTIVITY_LOG_DATA_FAIL = 'FETCH_ACTIVITY_LOG_DATA_FAIL';
export const SET_ACTIVITY_LOG_PAGE_NUMBER = 'SET_ACTIVITY_LOG_PAGE_NUMBER';
export const INCREMENT_ACTIVITY_LOG_PAGE_NUMBER = 'INCREMENT_ACTIVITY_LOG_PAGE_NUMBER';

export const SET_ACTIVITY_LOG_ADDITIONAL_DATA_LOADING = 'SET_ACTIVITY_LOG_ADDITIONAL_DATA_LOADING';
export const FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_SUCCESS = 'FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_SUCCESS';
export const FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_FAIL = 'FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_FAIL';

export const MAP_DEFAULT_CENTER_LAT = 39.712469;
export const MAP_DEFAULT_CENTER_LNG = -100.458984;

export const MAP_SEARCH_DEFAULT_ZOOM = 11;
export const MAP_INITIAL_ZOOM = 5;
export const SEARCH_FILTERING_DEBOUNCE_MS = 500;

export const REGEX_LAT_LNG_PATTERN = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const NUMBERS_PATTERN = /^[0-9]*$/;
export const SINGLE_DECIMAL_NUMBER_PATTERN = /^\d*\.?(?:\d{1,1})?$/;

// Alert Configuration
export const CREATE_NEW_ALERT_START = 'CREATE_NEW_ALERT_START';
export const CREATE_NEW_ALERT_CANCEL = 'CREATE_NEW_ALERT_CANCEL';
export const SET_CREATED_ALERT_RULE_NAME = 'SET_CREATED_ALERT_RULE_NAME';
export const TOGGLE_ALL_VEHICLE_IDS = 'TOGGLE_ALL_VEHICLE_IDS';
export const SET_ALERT_VEHICLE_IDS = 'SET_ALERT_VEHICLE_IDS';
export const SET_ALERT_VEHICLE_GROUP_IDS = 'SET_ALERT_VEHICLE_GROUP_IDS';
export const SET_GEOFENCE_OCCUPANCY_IDS = 'SET_GEOFENCE_OCCUPANCY_IDS';
export const ADD_NOTIFY_EMAIL_CONFIG = 'ADD_NOTIFY_EMAIL_CONFIG';
export const REMOVE_NOTIFY_EMAIL_CONFIG = 'REMOVE_NOTIFY_EMAIL_CONFIG';

export const SET_NOTIFICATIONS_FREQUENCY = 'SET_NOTIFICATIONS_FREQUENCY';
export const SET_ALERT_RULE_CUSTOMER_DATA = 'SET_ALERT_RULE_CUSTOMER_DATA';
export const SET_INCLUDE_SENSOR_DATA_VALUE = 'SET_INCLUDE_SENSOR_DATA_VALUE';

export const CREATE_NEW_ALERT_RULE_BEGIN = 'CREATE_NEW_ALERT_RULE_BEGIN';
export const CREATE_NEW_ALERT_RULE_SUCCESS = 'CREATE_NEW_ALERT_RULE_SUCCESS';
export const OPEN_CREATE_ALERT_DIALOG = 'OPEN_CREATE_ALERT_DIALOG';
export const OPEN_UPDATE_ALERT_DIALOG = 'OPEN_UPDATE_ALERT_DIALOG';
export const OPEN_DELETE_ALERT_DIALOG = 'OPEN_DELETE_ALERT_DIALOG';
export const CREATE_NEW_ALERT_RULE_FAIL = 'CREATE_NEW_ALERT_RULE_FAIL';

export const SET_ALERTS_LIST_SEARCH_QUERY = 'SET_ALERTS_LIST_SEARCH_QUERY';
export const FETCH_ALERTS_LIST_DATA_BEGIN = 'FETCH_ALERTS_LIST_DATA_BEGIN';
export const FETCH_ALERTS_LIST_DATA_SUCCESS = 'FETCH_ALERTS_LIST_DATA_SUCCESS';
export const FETCH_ALERTS_LIST_DATA_FAIL = 'FETCH_ALERTS_LIST_DATA_FAIL';

export const CREATE_ALERT_RULE_PREDICATE = 'CREATE_ALERT_RULE_PREDICATE';
export const REMOVE_ALERT_RULE_PREDICATE = 'REMOVE_ALERT_RULE_PREDICATE';

export const LOAD_ALERT_RULE_BEGIN = 'LOAD_ALERT_RULE_BEGIN';
export const LOAD_ALERT_RULE_SUCCESS = 'LOAD_ALERT_RULE_SUCCESS';
export const LOAD_ALERT_RULE_FAIL = 'LOAD_ALERT_RULE_FAIL';

export const UPDATE_ALERT_RULE_BEGIN = 'UPDATE_ALERT_RULE_BEGIN';
export const UPDATE_ALERT_RULE_SUCCESS = 'UPDATE_ALERT_RULE_SUCCESS';
export const UPDATE_ALERT_RULE_FAIL = 'UPDATE_ALERT_RULE_FAIL';

export const DELETE_ALERT_RULE_BEGIN = 'DELETE_ALERT_RULE_BEGIN';
export const TOGGLE_ALERT_DELETE_MODAL = 'TOGGLE_ALERT_DELETE_MODAL';
export const DELETE_ALERT_RULE_SUCCESS = 'DELETE_ALERT_RULE_SUCCESS';
export const DELETE_ALERT_RULE_FAIL = 'DELETE_ALERT_RULE_FAIL';

export const TOGGLE_ALERT_NOTIFICATIONS_SCREEN = 'TOGGLE_ALERT_NOTIFICATIONS_SCREEN';

export const SET_SELECTED_ALERT_PREDICATES = 'SET_SELECTED_ALERT_PREDICATES';
export const SET_ALERT_TYPE_OPTION_DROPDOWN_VALUE = 'SET_ALERT_TYPE_OPTION_DROPDOWN_VALUE';

export const ENABLE_ALERT_RULE_BEGIN = 'ENABLE_ALERT_RULE_BEGIN';
export const ENABLE_ALERT_RULE_SUCCESS = 'ENABLE_ALERT_RULE_SUCCESS';
export const ENABLE_ALERT_RULE_FAIL = 'ENABLE_ALERT_RULE_FAIL';

export const DISABLE_ALERT_RULE_BEGIN = 'DISABLE_ALERT_RULE_BEGIN';
export const DISABLE_ALERT_RULE_SUCCESS = 'DISABLE_ALERT_RULE_SUCCESS';
export const DISABLE_ALERT_RULE_FAIL = 'DISABLE_ALERT_RULE_FAIL';
export const SET_ALERT_RULE_DURATION_INPUT_VALUE = 'SET_ALERT_RULE_DURATION_INPUT_VALUE';
export const SET_ALERT_RULE_LOAD_WEIGHT_INPUT_VALUE = 'SET_ALERT_RULE_LOAD_WEIGHT_INPUT_VALUE';
export const RESET_ALERT_RULE_STATE = 'RESET_ALERT_RULE_STATE';
export const SET_FUEL_LEVEL_THRESHOLD_VALUE = 'SET_FUEL_LEVEL_THRESHOLD_VALUE';
export const SET_THERMO_KING_FAULT_LEVEL_VALUE = 'SET_THERMO_KING_FAULT_LEVEL_VALUE';
export const SET_GEOFENCE_OCCUPANCY_INVERTED_VALUE = 'SET_GEOFENCE_OCCUPANCY_INVERTED_VALUE';
export const SET_ASSET_GEOFENCE_THRESHOLD_TRAILERS_VALUE = 'SET_ASSET_GEOFENCE_THRESHOLD_TRAILERS_VALUE';
export const SET_SELECTED_ASSET_GEOFENCE_THRESHOLD_GEOFENCES = 'SET_SELECTED_ASSET_GEOFENCE_THRESHOLD_GEOFENCES';
export const allGeofencesSelectOption = {
  key: 'ALL_GEOFENCES_SELECT_OPTION',
  name: 'All Geofences',
  selectFormat: [{ label: 'All Geofences', value: 'All Geofences' }],
};

// All VINs and Unit IDs
export const TOGGLE_ALL_VINS_AND_UNIT_IDS_LOADING = 'TOGGLE_ALL_VINS_AND_UNIT_IDS_LOADING';
export const FETCH_ALL_VINS_AND_UNIT_IDS_DATA_FAIL = 'FETCH_ALL_VINS_AND_UNIT_IDS_DATA_FAIL';
export const RECEIVE_ALL_TRUCKS_VINS_UNIT_IDS = 'RECEIVE_ALL_TRUCKS_VINS_UNIT_IDS';
export const RECEIVE_TRAILERS_PER_CUSTOMER_COUNT = 'RECEIVE_TRAILERS_PER_CUSTOMER_COUNT';
export const SET_INITIAL_VIN = 'SET_INITIAL_VIN';
export const DROPDOWN_VIN_OPTION = 'DROPDOWN_VIN_OPTION';
export const DROPDOWN_UNIT_ID_OPTION = 'DROPDOWN_UNIT_ID_OPTION';

export const FETCH_ALL_VEHICLES_BEGIN = 'FETCH_ALL_VEHICLES_BEGIN';
export const FETCH_ALL_VEHICLES_SUCCESS = 'FETCH_ALL_VEHICLES_SUCCESS';
export const FETCH_ALL_VEHICLES_FAIL = 'FETCH_ALL_VEHICLES_FAIL';

export const CREATE_REPORT_CONFIG_START = 'CREATE_REPORT_CONFIG_START';
export const CREATE_REPORT_CONFIG_CANCEL = 'CREATE_REPORT_CONFIG_CANCEL';
export const SET_YARD_REPORT_NAME = 'SET_YARD_REPORT_NAME';
export const SET_REPORT_CUSTOMER_DATA = 'SET_REPORT_CUSTOMER_DATA';
export const SET_YARD_REPORT_VINS = 'SET_YARD_REPORT_VINS';
export const SET_YARD_REPORT_VEHICLE_GROUPS = 'SET_YARD_REPORT_VEHICLE_GROUPS';
export const TOGGLE_ALL_YARD_REPORT_VEHICLES = 'TOGGLE_ALL_YARD_REPORT_VEHICLES';
export const SET_YARD_REPORT_NOTIFY_EMAIL = 'SET_YARD_REPORT_NOTIFY_EMAIL';
export const REMOVE_YARD_REPORT_NOTIFY_EMAIL = 'REMOVE_YARD_REPORT_NOTIFY_EMAIL';
export const SET_YARD_REPORT_TIME_SCHEDULES = 'SET_YARD_REPORT_TIME_SCHEDULES';
export const SET_ABS_SUMMARY_NOTIFICATIONS_FREQUENCY_CATEGORY = 'SET_ABS_SUMMARY_NOTIFICATIONS_FREQUENCY_CATEGORY';
export const SET_REPORT_HISTORY_TIMEFRAME_VALUE = 'SET_REPORT_HISTORY_TIMEFRAME_VALUE';
export const SET_REPORT_EMAIL_FREQUENCY = 'SET_REPORT_EMAIL_FREQUENCY';

//  New Report Config CRUD-s
export const FETCH_REPORT_CONFIGS_BEGIN = 'FETCH_REPORT_CONFIGS_BEGIN';
export const FETCH_REPORT_CONFIGS_SUCCESS = 'FETCH_REPORT_CONFIGS_SUCCESS';
export const FETCH_REPORT_CONFIGS_FAIL = 'FETCH_REPORT_CONFIGS_FAIL';

export const CREATE_REPORT_CONFIG_BEGIN = 'CREATE_REPORT_CONFIG_BEGIN';
export const CREATE_REPORT_CONFIG_SUCCESS = 'CREATE_REPORT_CONFIG_SUCCESS';
export const CREATE_REPORT_CONFIG_FAIL = 'CREATE_REPORT_CONFIG_FAIL';

export const UPDATE_REPORT_CONFIG_BEGIN = 'UPDATE_REPORT_CONFIG_BEGIN';
export const UPDATE_REPORT_CONFIG_SUCCESS = 'UPDATE_REPORT_CONFIG_SUCCESS';
export const UPDATE_REPORT_CONFIG_FAIL = 'UPDATE_REPORT_CONFIG_FAIL';

export const DELETE_REPORT_CONFIG_BEGIN = 'DELETE_REPORT_CONFIG_BEGIN';
export const DELETE_REPORT_CONFIG_SUCCESS = 'DELETE_REPORT_CONFIG_SUCCESS';
export const DELETE_REPORT_CONFIG_FAIL = 'DELETE_REPORT_CONFIG_FAIL';

export const LOAD_REPORT_CONFIG_BEGIN = 'LOAD_REPORT_CONFIG_BEGIN';
export const LOAD_REPORT_CONFIG_SUCCESS = 'LOAD_REPORT_CONFIG_SUCCESS';
export const LOAD_REPORT_CONFIG_FAIL = 'LOAD_REPORT_CONFIG_FAIL';

export const UPDATE_REPORT_CONFIG_STATUS_BEGIN = 'UPDATE_REPORT_CONFIG_STATUS_BEGIN';
export const UPDATE_REPORT_CONFIG_STATUS_SUCCESS = 'UPDATE_REPORT_CONFIG_STATUS_SUCCESS';
export const UPDATE_REPORT_CONFIG_STATUS_FAIL = 'UPDATE_REPORT_CONFIG_STATUS_FAIL';

export const SET_REPORT_CONFIG_SCHEDULE_DETAILS = 'SET_REPORT_CONFIG_SCHEDULE_DETAILS';

export const OPEN_ALERT_DIALOG_BOX = 'OPEN_ALERT_DIALOG_BOX';
export const CLOSE_ALERT_DIALOG_BOX = 'CLOSE_ALERT_DIALOG_BOX';

export const SET_REPORT_GEOFENCE_LOCATIONS = 'SET_REPORT_GEOFENCE_LOCATIONS';
export const TOGGLE_ALL_REPORT_GEOFENCES = 'TOGGLE_ALL_REPORT_GEOFENCES';

export const CREATE_YARD_REPORT_CONFIG_BEGIN = 'CREATE_YARD_REPORT_CONFIG_BEGIN';
export const CREATE_YARD_REPORT_CONFIG_FAIL = 'CREATE_YARD_REPORT_CONFIG_FAIL';
export const CREATE_YARD_REPORT_CONFIG_SUCCESS = 'CREATE_YARD_REPORT_CONFIG_SUCCESS';

export const FETCH_ALL_REPORT_CONGIFS_BEGIN = 'FETCH_ALL_REPORT_CONGIFS_BEGIN';
export const FETCH_ALL_REPORT_CONGIFS_SUCCESS = 'FETCH_ALL_REPORT_CONGIFS_SUCCESS';
export const FETCH_ALL_REPORT_CONGIFS_FAIL = 'FETCH_ALL_REPORT_CONGIFS_FAIL';

export const LOAD_YARD_REPORT_CONFIG_BEGIN = 'LOAD_YARD_REPORT_CONFIG_BEGIN';
export const LOAD_YARD_REPORT_CONFIG_FAIL = 'LOAD_YARD_REPORT_CONFIG_FAIL';
export const LOAD_YARD_REPORT_CONFIG_SUCCESS = 'LOAD_YARD_REPORT_CONFIG_SUCCESS';

export const DELETE_YARD_REPORT_CONFIG_BEGIN = 'DELETE_YARD_REPORT_CONFIG_BEGIN';
export const DELETE_YARD_REPORT_CONFIG_FAIL = 'DELETE_YARD_REPORT_CONFIG_FAIL';
export const DELETE_YARD_REPORT_CONFIG_SUCCESS = 'DELETE_YARD_REPORT_CONFIG_SUCCESS';

export const UPDATE_YARD_REPORT_CONFIG_BEGIN = 'UPDATE_YARD_REPORT_CONFIG_BEGIN';
export const UPDATE_YARD_REPORT_CONFIG_FAIL = 'UPDATE_YARD_REPORT_CONFIG_FAIL';
export const UPDATE_YARD_REPORT_CONFIG_SUCCESS = 'UPDATE_YARD_REPORT_CONFIG_SUCCESS';
export const TOGGLE_DELETE_REPORT_CONFIG_MODAL = 'TOGGLE_DELETE_REPORT_CONFIG_MODAL';

export const FETCH_ALL_DWELL_REPORT_CONFIGS_BEGIN = 'FETCH_ALL_DWELL_REPORT_CONFIGS_BEGIN';
export const FETCH_ALL_DWELL_REPORT_CONFIGS_FAIL = 'FETCH_ALL_DWELL_REPORT_CONFIGS_FAIL';
export const FETCH_ALL_DWELL_REPORT_CONFIGS_SUCCESS = 'FETCH_ALL_DWELL_REPORT_CONFIGS_SUCCESS';
export const CREATE_DWELL_REPORT_CONFIG_BEGIN = 'CREATE_DWELL_REPORT_CONFIG_BEGIN';
export const CREATE_DWELL_REPORT_CONFIG_FAIL = 'CREATE_DWELL_REPORT_CONFIG_FAIL';
export const CREATE_DWELL_REPORT_CONFIG_SUCCESS = 'CREATE_DWELL_REPORT_CONFIG_SUCCESS';
export const UPDATE_DWELL_REPORT_CONFIG_BEGIN = 'UPDATE_DWELL_REPORT_CONFIG_BEGIN';
export const UPDATE_DWELL_REPORT_CONFIG_FAIL = 'UPDATE_DWELL_REPORT_CONFIG_FAIL';
export const UPDATE_DWELL_REPORT_CONFIG_SUCCESS = 'UPDATE_DWELL_REPORT_CONFIG_SUCCESS';
export const LOAD_DWELL_REPORT_CONFIG_BEGIN = 'LOAD_DWELL_REPORT_CONFIG_BEGIN';
export const LOAD_DWELL_REPORT_CONFIG_FAIL = 'LOAD_DWELL_REPORT_CONFIG_FAIL';
export const LOAD_DWELL_REPORT_CONFIG_SUCCESS = 'LOAD_DWELL_REPORT_CONFIG_SUCCESS';
export const DELETE_DWELL_REPORT_CONFIG_BEGIN = 'DELETE_DWELL_REPORT_CONFIG_BEGIN';
export const DELETE_DWELL_REPORT_CONFIG_FAIL = 'DELETE_DWELL_REPORT_CONFIG_FAIL';
export const DELETE_DWELL_REPORT_CONFIG_SUCCESS = 'DELETE_DWELL_REPORT_CONFIG_SUCCESS';

export const CREATE_THERMO_KING_REPORT_BEGIN = 'CREATE_THERMO_KING_REPORT_BEGIN';
export const CREATE_THERMO_KING_REPORT_SUCCESS = 'CREATE_THERMO_KING_REPORT_SUCCESS';
export const CREATE_THERMO_KING_REPORT_FAIL = 'CREATE_THERMO_KING_REPORT_FAIL';

export const SET_TOGGLE_ALL_ASSETS_MODAL = 'SET_TOGGLE_ALL_ASSETS_MODAL';

export const UPDATE_YARD_REPORT_CONFIG_STATUS_BEGIN = 'UPDATE_YARD_REPORT_CONFIG_STATUS_BEGIN';
export const UPDATE_YARD_REPORT_CONFIG_STATUS_FAIL = 'UPDATE_YARD_REPORT_CONFIG_STATUS_FAIL';
export const UPDATE_YARD_REPORT_CONFIG_STATUS_SUCCESS = 'UPDATE_YARD_REPORT_CONFIG_STATUS_SUCCESS';

export const UPDATE_DWELL_REPORT_CONFIG_STATUS_BEGIN = 'UPDATE_DWELL_REPORT_CONFIG_STATUS_BEGIN';
export const UPDATE_DWELL_REPORT_CONFIG_STATUS_FAIL = 'UPDATE_DWELL_REPORT_CONFIG_STATUS_FAIL';
export const UPDATE_DWELL_REPORT_CONFIG_STATUS_SUCCESS = 'UPDATE_DWELL_REPORT_CONFIG_STATUS_SUCCESS';

// Fleet Status
export const FETCH_ALL_FLEET_TRAILERS_BEGIN = 'FETCH_ALL_FLEET_TRAILERS_BEGIN';
export const FETCH_ALL_FLEET_TRAILERS_SUCCESS = 'FETCH_ALL_FLEET_TRAILERS_SUCCESS';
export const FETCH_ALL_FLEET_TRAILERS_FAIL = 'FETCH_ALL_FLEET_TRAILERS_FAIL';

// Customers
export const CUSTOMERS_LOADING = 'CUSTOMERS_LOADING';
export const CUSTOMERS_ERROR = 'CUSTOMERS_ERROR';
export const CUSTOMERS_SUCCESS = 'CUSTOMERS_SUCCESS';

//Customer Management
export const OPEN_TRAILER_TRANSFERED_ALERT_DIALOG = 'OPEN_TRAILER_TRANSFERED_ALERT_DIALOG';
export const OPEN_CREATE_CUSTOMER_ALERT_DIALOG = 'OPEN_CREATE_CUSTOMER_ALERT_DIALOG';

// Vehicle groups
export const VEHICLE_GROUPS_LOADING = 'VEHICLE_GROUPS_LOADING';
export const VEHICLE_GROUPS_ERROR = 'VEHICLE_GROUPS_ERROR';
export const VEHICLE_GROUPS_SUCCESS = 'VEHICLE_GROUPS_SUCCESS';

export const ASSET_COUNT = 'Asset Count';

export const ALL_VINS_GROUP = 'All VINs';
export const NO_VINS_GROUP = 'No VINs';

export const ALERT_CONFIGURATION_ROUTE = '/alertconfiguration';
export const CREATE_NEW_CUSTOMER = 'createNewCustomer';
export const ADD_TO_EXISTING_CUSTOMER = 'addToExistingCustomer';

export const PUBLISH_OTA_CONFIGURATION = 'publishConfiguration';
export const APPLY_CONFIGURATION = 'applyConfiguration';
export const PUBLISH_MOREY_SCRIPT = 'publishMoreyScript';
export const APPLY_MOREY_SCRIPT = 'applyMoreyScript';
export const PUBLISH_FW = 'publishFW';
export const APPLY_FW = 'applyFW';
export const RESET_DEVICES = 'resetDevices';

// OTA
export const OTA_LOADING = 'OTA_LOADING';
export const OTA_ERROR = 'OTA_ERROR';
export const OTA_SUCCESS = 'OTA_SUCCESS';
export const DEVICE_CONFIG_SUCCESS = 'DEVICE_CONFIG_SUCCESS';
export const DEVICE_CONFIG_LOADING = 'DEVICE_CONFIG_LOADING';
export const DEVICE_CONFIG_ERROR = 'DEVICE_CONFIG_ERROR';
export const MOREY_SCRIPT_UPLOAD_ERROR = 'MOREY_SCRIPT_UPLOAD_ERROR';
export const MOREY_SCRIPT_UPLOAD_SUCCESS = 'MOREY_SCRIPT_UPLOAD_SUCCESS';
export const CONFIRM_DEVICE_CONFIG_CREATE = 'CONFIRM_DEVICE_CONFIG_CREATE';
export const CONFIRM_DEVICE_CONFIG_SUBMIT = 'CONFIRM_DEVICE_CONFIG_SUBMIT';

export const DEVICE_SCRIPTS_LOADING = 'DEVICE_SCRIPTS_LOADING';
export const DEVICE_SCRIPTS_ERROR = 'DEVICE_SCRIPTS_ERROR';
export const DEVICE_SCRIPTS_SUCCESS = 'DEVICE_SCRIPTS_SUCCESS';
export const DEVICE_MAIN_APP_LOADING = 'DEVICE_MAIN_APP_LOADING';
export const DEVICE_MAIN_APP_ERROR = 'DEVICE_MAIN_APP_ERROR';
export const DEVICE_MAIN_APP_SUCCESS = 'DEVICE_MAIN_APP_SUCCESS';
export const MOREY_MAIN_APP_UPLOAD_SUCCESS = 'MOREY_MAIN_APP_UPLOAD_SUCCESS';
export const MOREY_MAIN_APP_UPLOAD_ERROR = 'MOREY_MAIN_APP_UPLOAD_ERROR';

// Maintenance
export const TOGGLE_HEADER_LOADING = 'TOGGLE_HEADER_LOADING';
export const RECEIVE_HEADER_DATA = 'RECEIVE_HEADER_DATA';
export const FILTER_MAINTENANCE_DATA = 'FILTER_MAINTENANCE_DATA';
export const FILTER_HEADER_DATA = 'FILTER_HEADER_DATA';
